import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { INewTradeRequirement, ITradeRequirement } from 'shared/model/tradeRequirement.model';
import axios from '../../../../shared/config/axios-interceptor';
import { IParams } from '../../../../shared/shared-interfaces';

const prefix = 'external/office-requirements';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ITradeRequirement[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITradeRequirement>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ITradeRequirement, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<ITradeRequirement>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewTradeRequirement, thunkAPI) => {
  try {
    const { data } = await axios.post<ITradeRequirement>(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
