import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../../shared/model/user.model';
import {
  account,
  changePassword,
  checkWalletAddress,
  login,
  loginKeyCloak,
  register,
  resetPassword,
  resetWalletAddress,
  sendOtpToUpdateSecurityInfo,
  sendResetPasswordKey,
  updateAccount,
  updateSecurityInfo,
} from './auth.api';

interface IInitialLoginState {
  loading: boolean;
  errorMessage: string | null;
  user: IUser | null;
  loginSuccess: boolean;
  registerSuccess: boolean;
  getAccountSuccess: boolean;
  updateUserSuccess: boolean;
  token: string | null;
  resetPasswordSuccess: boolean;
  sendOtpSuccess: boolean;
  checkWalletAddressSuccess: boolean;
}

const initialState: IInitialLoginState = {
  loading: false,
  registerSuccess: false,
  errorMessage: null,
  loginSuccess: false,
  getAccountSuccess: false,
  updateUserSuccess: false,
  token: null,
  user: null,
  resetPasswordSuccess: false,
  sendOtpSuccess: false,
  checkWalletAddressSuccess: false,
};

const { actions, reducer } = createSlice({
  name: 'authenticationSlice',
  initialState,
  reducers: {
    fetching(state) {
      state.loading = true;
    },
    logout(state) {
      state.token = null;
      state.user = null;
      localStorage.removeItem('authentication_token');
    },
    storeToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    resetAll(state) {
      state.registerSuccess = false;
      state.loading = false;
      state.loginSuccess = false;
      state.getAccountSuccess = false;
      state.token = null;
      state.user = null;
      state.errorMessage = null;
      state.updateUserSuccess = false;
    },
    resetEntity(state) {
      state.updateUserSuccess = false;
      state.registerSuccess = false;
      state.getAccountSuccess = false;
      state.loginSuccess = false;
      state.loading = false;
      state.errorMessage = null;
      state.checkWalletAddressSuccess = false;
    },
    resetSendPasswordKey(state) {
      state.errorMessage = null;
      state.sendOtpSuccess = false;
      state.resetPasswordSuccess = false;
    },
  },
  extraReducers: {
    [login.fulfilled.type]: (state, { payload }: PayloadAction<{ id_token: string }>) => {
      localStorage.setItem('authentication_token', payload.id_token);
      state.token = payload.id_token;
      state.loginSuccess = true;
      state.loading = false;
    },
    [login.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      localStorage.removeItem('authentication_token');
      state.errorMessage = payload?.message;
      state.loading = false;
      state.loginSuccess = false;
    },
    [register.fulfilled.type]: (state, { payload }: PayloadAction<{ id_token: string }>) => {
      localStorage.setItem('authentication_token', payload.id_token);
      state.token = payload.id_token;
      state.registerSuccess = true;
      state.loading = false;
    },
    [register.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      localStorage.removeItem('authentication_token');
      state.errorMessage = payload?.message;
      state.loading = false;
      state.registerSuccess = false;
    },
    [account.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.getAccountSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [account.rejected.type]: (state, { payload }) => {
      localStorage.removeItem('authentication_token');
      state.getAccountSuccess = false;
      state.loading = false;
    },
    [updateAccount.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.updateUserSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [updateAccount.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.updateUserSuccess = false;
      state.loading = false;
    },
    [sendResetPasswordKey.fulfilled.type]: (state, { payload }) => {
      state.sendOtpSuccess = true;
      state.loading = false;
    },
    [sendResetPasswordKey.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.sendOtpSuccess = false;
      state.loading = false;
    },
    [resetPassword.fulfilled.type]: (state, { payload }) => {
      state.resetPasswordSuccess = true;
      state.loading = false;
    },
    [resetPassword.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.resetPasswordSuccess = false;
      state.loading = false;
    },
    [changePassword.fulfilled.type]: (state, { payload }) => {
      state.updateUserSuccess = true;
      state.loading = false;
    },
    [changePassword.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.updateUserSuccess = false;
      state.loading = false;
    },
    [sendOtpToUpdateSecurityInfo.fulfilled.type]: (state, { payload }) => {
      state.sendOtpSuccess = true;
      state.loading = false;
    },
    [sendOtpToUpdateSecurityInfo.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.sendOtpSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [updateSecurityInfo.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.updateUserSuccess = true;
      state.loading = false;
    },
    [updateSecurityInfo.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.updateUserSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [resetWalletAddress.fulfilled.type]: (state, { payload }) => {
      state.sendOtpSuccess = true;
      state.loading = false;
    },
    [resetWalletAddress.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.sendOtpSuccess = false;
      state.loading = false;
    },
    [checkWalletAddress.fulfilled.type]: (state, { payload }) => {
      state.checkWalletAddressSuccess = true;
      state.loading = false;
    },
    [checkWalletAddress.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.checkWalletAddressSuccess = false;
      state.loading = false;
    },
    [loginKeyCloak.fulfilled.type]: (state, { payload }: PayloadAction<{ id_token: string }>) => {
      localStorage.setItem('authentication_token', payload.id_token);
      state.token = payload.id_token;
      state.loginSuccess = true;
      state.loading = false;
    },
    [loginKeyCloak.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      localStorage.removeItem('authentication_token');
      state.errorMessage = payload?.message;
      state.loading = false;
      state.loginSuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity, logout, storeToken, resetSendPasswordKey } = actions;
export default reducer;
