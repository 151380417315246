import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Departments, PrivateInternalTasks } from 'shared/enumeration/departments';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const TradeOfficeManagement = React.lazy(() => import('./TradeOfficeManagement/TradeOfficeManagement'));
const TradeRequirements = React.lazy(() => import('./TradeRequirements/TradeRequirements'));
const TradeRequirementUpdate = React.lazy(() => import('./TradeRequirements/TradeRequirementUpdate'));

const TradeOfficesLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_ADMIN,
          SystemRoles.ROLE_HEAD,
          PrivateInternalTasks.UPDATE_OFFICE_REGISTRATIONS,
          PrivateInternalTasks.CONFIG_OFFICE_REGISTRATIONS,
        ]}
        department={Departments.DEPT_OFFICE}
      >
        <TradeOfficeManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/overview',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_ADMIN,
          SystemRoles.ROLE_HEAD,
          PrivateInternalTasks.UPDATE_OFFICE_REGISTRATIONS,
          PrivateInternalTasks.CONFIG_OFFICE_REGISTRATIONS,
        ]}
        department={Departments.DEPT_OFFICE}
      >
        <TradeOfficeManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/requirements',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_ADMIN,
          SystemRoles.ROLE_HEAD,
          PrivateInternalTasks.UPDATE_OFFICE_REGISTRATIONS,
          PrivateInternalTasks.CONFIG_OFFICE_REGISTRATIONS,
        ]}
        department={Departments.DEPT_OFFICE}
      >
        <TradeRequirements />
      </ProtectedComponent>
    ),
  },
  {
    path: '/requirements/create',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_ADMIN,
          SystemRoles.ROLE_HEAD,
          PrivateInternalTasks.CONFIG_OFFICE_REGISTRATIONS,
        ]}
        department={Departments.DEPT_OFFICE}
      >
        <TradeRequirementUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: '/requirements/:id/update',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_ADMIN,
          SystemRoles.ROLE_HEAD,
          PrivateInternalTasks.CONFIG_OFFICE_REGISTRATIONS,
        ]}
        department={Departments.DEPT_OFFICE}
      >
        <TradeRequirementUpdate />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const TradeOfficesRoutes = () => useRoutes(TradeOfficesLayout);
export default TradeOfficesRoutes;
