import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../shared/config/axios-interceptor';
import { IDurationRisk, INewDurationRisk } from '../../../../shared/model/listingType.model';

const prefix = 'duration-risks';

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (fields: { provinceCode: string; typeId: string }, thunkAPI) => {
    try {
      const params = pickBy(fields);
      const { data } = await axios.get<IDurationRisk[]>(`${prefix}`, { params });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewDurationRisk[], thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IDurationRisk[], thunkAPI) => {
  try {
    const { data } = await axios.put<IDurationRisk[]>(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
