import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { INewRevenueAllocation, IRevenueAllocation } from 'shared/model/revenueAllocation.model';
import { IParams } from 'shared/shared-interfaces';
import axios from '../../../../../shared/config/axios-interceptor';

const prefix = 'revenue-allocation';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IRevenueAllocation[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IRevenueAllocation>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewRevenueAllocation, thunkAPI) => {
  try {
    const { data } = await axios.post<INewRevenueAllocation>(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IRevenueAllocation, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IRevenueAllocation>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
