import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../shared/config/axios-interceptor';
import { FavListing } from '../../../../shared/enumeration/favListing';
import { IListing, INewListing } from '../../../../shared/model/listing.model';
import { IListingFilter } from '../CoverComponents/SearchBoxComponent';
import { IUpdateFavEntities } from './listing.reducer';

const prefix = 'public/listings';

const favListings = 'public/fav-listings';

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (fields: IListingFilter | undefined, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IListing[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IListing>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IListing, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IListing>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewListing, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getSecondaryEntities = createAsyncThunk(
  `get-all-secondary-${prefix}`,
  async (fields: IListingFilter | undefined, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IListing[]>(`${prefix}/secondary`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPrimaryEntities = createAsyncThunk(
  `get-all-primary-${prefix}`,
  async (fields: IListingFilter | undefined, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IListing[]>(`${prefix}/primary`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IUpdateFavListing {
  listingId: string;
  option: FavListing;
}

export const updateFavEntity = createAsyncThunk(
  `update-one-${favListings}`,
  async (body: IUpdateFavListing, thunkAPI) => {
    try {
      await axios.post<IUpdateFavListing>(`${favListings}`, body);
      return body;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateFavEntities = createAsyncThunk(
  `update-many-${favListings}`,
  async (body: IUpdateFavEntities, thunkAPI) => {
    try {
      const entities: IUpdateFavListing[] = [];
      for (const property in body) {
        entities.push({ listingId: property, option: body[property] });
      }
      if (!entities.length) return;
      return await axios.post<IUpdateFavListing>(`${favListings}/many`, { entities });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
