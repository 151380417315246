import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const CommissionConfigsManagement = React.lazy(() => import('./CommissionConfigsManagement'));
const CommissionConfigsUpdate = React.lazy(() => import('./CommissionConfigsUpdate'));

const CommissionConfigsLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <CommissionConfigsManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <CommissionConfigsUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: '/create',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <CommissionConfigsUpdate />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const CommissionConfigsRoutes = () => useRoutes(CommissionConfigsLayout);
export default CommissionConfigsRoutes;
