export enum TaskStatus {
  ASSIGN = 'ASSIGN',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FINISHED = 'FINISHED',
}

export const taskStatusArray: TaskStatus[] = [
  TaskStatus.ASSIGN,
  TaskStatus.FINISHED,
  TaskStatus.APPROVED,
  TaskStatus.REJECTED,
];

export const mapTaskStatus: { [key in TaskStatus]: string } = {
  [TaskStatus.ASSIGN]: 'Đã giao',
  [TaskStatus.APPROVED]: 'Đạt',
  [TaskStatus.REJECTED]: 'Không đạt',
  [TaskStatus.FINISHED]: 'Hoàn thành',
};

export const mapTaskStatusBadge: { [key in TaskStatus]: string } = {
  [TaskStatus.ASSIGN]: 'info',
  [TaskStatus.APPROVED]: 'success',
  [TaskStatus.REJECTED]: 'danger',
  [TaskStatus.FINISHED]: 'warning',
};
