import {
  cilBank,
  cilBathroom,
  cilBeachAccess,
  cilBed,
  cilBuilding,
  cilBuildingBusiness,
  cilCasino,
  cilChild,
  cilChildFriendly,
  cilCity,
  cilDrinkAlcohol,
  cilFactory,
  cilFridge,
  cilGamepad,
  cilGarage,
  cilGolfAlt,
  cilHospital,
  cilHotel,
  cilHotTub,
  cilHouse,
  cilKitchen,
  cilLaundry,
  cilMedicalCross,
  cilPool,
  cilRunning,
  cilSchool,
  cilShower,
  cilSmokeFree,
  cilSmokingRoom,
  cilSofa,
  cilSpa,
  cilTennis,
  cilTennisBall,
  cilToilet,
  cilWc,
  cilWeights,
} from '@coreui/icons-pro';

export const potentialSet = Object.assign(
  {},
  {
    cilBank,
    cilChildFriendly,
    cilGarage,
    cilSmokingRoom,
    cilBeachAccess,
    cilPool,
    cilGolfAlt,
    cilWeights,
    cilDrinkAlcohol,
    cilChild,
    cilGamepad,
    cilRunning,
    cilTennis,
    cilTennisBall,
    cilLaundry,
    cilKitchen,
    cilHotel,
    cilShower,
    cilWc,
    cilSpa,
    cilMedicalCross,
    cilHotTub,
    cilCasino,
    cilBuilding,
    cilBuildingBusiness,
    cilBathroom,
    cilToilet,
    cilSofa,
    cilSmokeFree,
    cilSchool,
    cilHospital,
    cilFridge,
    cilFactory,
    cilBed,
    cilCity,
    cilHouse,
  }
);
