import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../shared/config/axios-interceptor';
import { IStage } from '../../../../shared/model/stage.model';
import { IParams } from '../../../../shared/shared-interfaces';

const prefix = 'external/stages';

export interface IStageFilter extends IParams {
  futureStage?: boolean;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IStageFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IStage[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IStage>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
