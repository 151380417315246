import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { NewOwnerOption } from 'shared/enumeration/newOwnerOption';
import axios from '../../../../shared/config/axios-interceptor';
import { RentStatus } from '../../../../shared/enumeration/commercialStatus';
import { CommercialTypes, Methods } from '../../../../shared/enumeration/cooperativeMethod';
import { RequestStatus } from '../../../../shared/enumeration/requestStatus';
import { INewProduct, IProduct } from '../../../../shared/model/listing.model';
import { IParams } from '../../../../shared/shared-interfaces';

const prefix = 'listings';

export interface IProductParams extends IParams {
  status?: string;
  project?: number;
  projectId?: string;
  level?: string;
  sellStatus?: string;
  rentStatus?: RentStatus;
  option?: string;
}

export const createEntity = createAsyncThunk(`register-${prefix}`, async (body: INewProduct, thunkAPI) => {
  try {
    const { listingAttributes } = body;
    const pickByListingAttributes = listingAttributes.filter((attribute) => attribute.value);
    const newBody = { ...body, listingAttributes: pickByListingAttributes };
    const { data } = await axios.post<INewProduct>(`${prefix}`, pickBy(newBody));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IProduct, thunkAPI) => {
  try {
    const { listingAttributes, id } = body;
    const pickByListingAttributes = listingAttributes.filter((attribute) => attribute.value);
    const newBody = { ...body, listingAttributes: pickByListingAttributes };
    const { data } = await axios.put<IProduct>(`${prefix}/${id}`, newBody);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntities = createAsyncThunk(
  `get-all-registered-${prefix}`,
  async (fields: IProductParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProduct[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSecondaryEntities = createAsyncThunk(
  `get-all-registered-${prefix}`,
  async (fields: IParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProduct[]>(`${prefix}/secondary`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(`get-one-registered-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IProduct>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-register`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IApprovalBody {
  id: string;
  status?: RequestStatus;
  suggest?: string;
  purchasePrice?: number;
  referenceRentalPrice?: number; // Giá thuê tham chiếu
  quality?: string;
  rejectNote?: string;
}

export const updateEntityStatus = createAsyncThunk(`update-${prefix}-status`, async (body: IApprovalBody, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IApprovalBody>(`${prefix}/${id}/status`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface ICooperationBody {
  id: string;
  option?: Methods;
  commercialTypes: CommercialTypes[];
  rentCost?: number;
  price?: number;
  period?: number;
  numberPeriod?: number;
  termPeriod?: string;
  borrowedCapital?: number;
}

export const updateEntityCooperation = createAsyncThunk(
  `update-${prefix}-cooperation`,
  async (body: ICooperationBody, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<ICooperationBody>(`${prefix}/${id}/option`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IListingPotentialSelected {
  id?: string;
  bonusRate?: number;
}
export interface IBoundaryBody {
  id: string;
  goodPrice?: number;
  goodRentCost?: number;
  fee?: number;
  listingPotentials: IListingPotentialSelected[];
  minPrice?: number;
  maxPrice?: number;
  value?: number;
  rentCost?: number;
  minRentCost?: number;
  maxRentCost?: number;
  period?: number;
}

export const updateEntityBoundary = createAsyncThunk(
  `update-listing-boundary`,
  async (body: IBoundaryBody, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IBoundaryBody>(`${prefix}/${id}/boundary`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IUpdateValueAndPeriod {
  id: string;
  period?: number;
  value?: number;
  rentCost?: number;
}

export const updateListingValue = createAsyncThunk(
  `update-listing-value-and-period`,
  async (body: IUpdateValueAndPeriod, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IUpdateValueAndPeriod>(`${prefix}/${id}/value`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IUpdateBusinessPriceBody {
  id: string;
  rentPrice?: number;
  sellPrice?: number;
}

export const updateBusinessPrice = createAsyncThunk(
  `update-listing-business-price`,
  async (body: IUpdateBusinessPriceBody, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IUpdateBusinessPriceBody>(`${prefix}/${id}/secondary-price`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IChangePublicShow {
  id: string;
  publicShow: boolean;
}

export const changePublicShow = createAsyncThunk(
  'change-public-listing-showing-in-primary',
  async (body: IChangePublicShow, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IChangePublicShow>(`${prefix}/${id}/change-public-show`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IAssignListingFranchisee {
  id: string;
  franchiseeId: string;
}

export const assignListingFranchisee = createAsyncThunk(
  'assign-listing-franchisee',
  async (body: IAssignListingFranchisee, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IAssignListingFranchisee>(`${prefix}/${id}/franchisee`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getExploitingEntities = createAsyncThunk(
  `get-all-exploiting-${prefix}`,
  async (fields: IProductParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProduct[]>(`${prefix}/secondary-owner`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntitySellStatus = createAsyncThunk('update-listing-sellStatus', async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.put<IProduct>(`${prefix}/${id}/confirm-transfer`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getOwnedEntities = createAsyncThunk(
  `get-all-ownership-registered-${prefix}`,
  async (fields: IParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProduct[]>(`${prefix}/owner`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IUpdateSoldListingOption {
  id: string;
  option: NewOwnerOption;
}

export const updateSoldListingOption = createAsyncThunk(
  'new-owner-update-sold-listing',
  async (body: IUpdateSoldListingOption, thunkAPI) => {
    try {
      const { id, option } = body;
      const { data } = await axios.put<IProduct>(`${prefix}/${id}/new-owner-option`, { option });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getListingSoldDate = createAsyncThunk(`get-${prefix}-sold-date`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IProduct>(`${prefix}/${id}/transaction-date`);
    return { id, soldDate: data };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
