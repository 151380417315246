import {
  cibFacebook,
  cibGmail,
  cibLinkedin,
  cibTwitter, cilCopyright,
  cilLocationPin,
  cilMail,
  cilPhone
} from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import { CButton, CCol, CContainer, CForm, CFormInput, CRow } from '@coreui/react-pro';
import React, { useEffect, useRef, useState } from 'react';
import anftLogo from '../../../assets/img/ANT_logo_FO.png';
import vectorImg from '../../../assets/img/Vector.png';
import { useRouter } from '../../../shared/utils/hooks/useRouter';
import { hideHeadrFootrRoutes } from './TheHeader';

interface ISocialMediaIcon {
  id: number;
  name: string;
  url: string;
  icon: any[];
}

interface IFooterBox {
  height: number | undefined;
  width: number | undefined;
}

const TheFooter = () => {
  const { location } = useRouter();
  const currentLocation = location.pathname;
  const isBOView = currentLocation.includes('/BO');
  const filterRef = useRef<HTMLDivElement>(null);

  const [footerBox, setFooterBox] = useState<IFooterBox>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    setFooterBox({ height: filterRef.current?.clientHeight, width: filterRef.current?.clientWidth });
  }, [filterRef]);

  const insideNoHeadFootRoutes = hideHeadrFootrRoutes.some((routes) => currentLocation.includes(routes));

  const iconArr: ISocialMediaIcon[] = [
    { id: 1, name: 'facebook', url: '#', icon: cibFacebook },
    { id: 6, name: 'linkedin', url: 'fb.com', icon: cibLinkedin },
    { id: 3, name: 'twitter', url: 'fb.com', icon: cibTwitter },
    { id: 4, name: 'gmail', url: 'fb.com', icon: cibGmail },
  ];

  return (
    <CRow className={isBOView || insideNoHeadFootRoutes ? 'd-none' : 'FO-Footer px-0 mx-0'}>
      <CCol xs={12} className="footer-start p-5">
        <CContainer className="position-relative">
          <CRow className="footer-background-wraper justify-content-center d-none d-xl-flex">
            <CCol xl={10}>
              <div
                style={{ backgroundImage: `url(${vectorImg})`, height: footerBox.height, width: footerBox.width }}
                className="footer-background w-100"
              ></div>
            </CCol>
          </CRow>

          <CRow ref={filterRef} className='position-relative'>
            <CCol xs={12}>
              <img className="c-sidebar-brand-full mb-3" src={anftLogo} height={65} alt="anftLogo" />
            </CCol>
            <CCol lg={12} xl={9}>
              <CRow>
                <CCol lg={12} xl={6} className="mb-3 info-box">
                  <br />
                  <p className="my-0 FO-white fs-6">TỐI ƯU DÒNG VỐN CHO TÀI SẢN BẤT ĐỘNG SẢN</p>
                  <br />
                  <p className="my-0 content-text footer-lh">
                    Áp dụng token ANFT và D'app như công cụ để Vận hành, Khai thác cổ phần và Sàn giao dịch Tài sản BĐS
                    thực tế. Giúp các chủ tài sản BĐS sở hữu Nền tảng công nghệ tối ưu dòng tiền khai thác BĐS theo chu
                    kỳ NGÀY
                  </p>
                </CCol>
                <CCol lg={12} xl={6} className="mb-3 contact-box">
                  <br />
                  <p className="my-0 FO-white fs-6">LIÊN HỆ</p>
                  <br />
                  <div>
                    <p className="my-0 content-text footer-lh">
                      <CIcon icon={cilPhone} className="icon-flipped text-white" size="sm" /> &nbsp; 0898558883
                    </p>
                    <p className="my-0 content-text footer-lh">
                      <CIcon icon={cilMail} className="text-white" size="sm" /> &nbsp; info@vream.vn
                    </p>
                    <p className="my-0 content-text footer-lh">
                      <CIcon icon={cilLocationPin} className="text-white" size="sm" /> &nbsp; 381 Đội Cấn, Quận Ba Đình, Hà Nội
                    </p>
                  </div>
                </CCol>
                <CCol lg={12} xl={6} className="mb-3 info-box">
                  <br />
                  <p className="my-0 FO-white fs-6">ĐỐI TƯỢNG THAM GIA</p>
                  <dl className="FO-white content-text">
                    <dt className="footer-lh fw-normal">Các chủ đầu tư dự án BĐS</dt>
                    <dt className="footer-lh fw-normal">Các sàn giao dịch Bất động sản F1</dt>
                    <dt className="footer-lh fw-normal">Các sàn giao dịch trung gian</dt>
                    <dt className="footer-lh fw-normal">Các công ty đang kinh doanh, môi giới BĐS</dt>
                    <dt className="footer-lh fw-normal">Các chủ tài sản BĐS cá nhân</dt>
                  </dl>
                </CCol>
                <CCol lg={12} xl={6} className="mb-3 contact-box">
                  <br />
                  <div>
                    <p className="my-0  FO-white fs-6 footer-lh">ĐĂNG KÝ NHẬN EMAIL</p>
                    <CForm>
                      <CRow>
                        <CCol xs={12} sm={8} className="pe-0 my-2">
                          <CFormInput type="email" id="exampleFormControlInput1" placeholder="Nhập email của bạn..." />
                        </CCol>
                        <CCol xs={12} sm={4} className="pe-0 my-2">
                          <CButton className="btn-mail-register text-anft-gradiant px-3">ĐĂNG KÝ</CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                    <small className="my-0 content-text lh-lg">
                      Chúng tôi sẽ liên tục gửi cho bạn các tài liệu về:
                    </small>
                    <dl className="content-text mb-1">
                      <dt className="fw-normal lh-lg">- Bản tin các tài sản BĐS vừa được cập nhật mới</dt>
                      <dt className="fw-normal lh-lg">
                        - Hướng dẫn cách sử dụng token ANFT để khai thác chốt quyền sở hữu, kiếm tiền từ các tài sản BĐS
                      </dt>
                      <dt className="fw-normal lh-lg">- Tài liệu đào tạo, video clip mô tả, hướng dẩn</dt>
                    </dl>
                  </div>
                </CCol>
              </CRow>
            </CCol>

            <CCol lg={12} xl={3} className="mb-3 menu-box">
              <br />
              <p className="my-0 FO-white fs-6">DANH MỤC</p>
              <br />
              <p className="my-0 content-text footer-lh fw-normal">Trang chủ</p>
              <p className="my-0 content-text footer-lh fw-normal">Sàn khai thác BĐS</p>
              <dl className="content-text ms-3 mb-1">
                <dt className="fw-normal footer-lh">Sàn sơ cấp</dt>
                <dt className="fw-normal footer-lh">Sàn thứ cấp</dt>
                <dt className="fw-normal footer-lh">Sàn nhượng quyền</dt>
              </dl>
              <p className="my-0 content-text footer-lh fw-normal">Quy hoạch</p>
              <p className="my-0 content-text footer-lh fw-normal">Thống kê thị trường</p>
              <p className="my-0 content-text footer-lh fw-normal">Dịch vụ hỗ trợ</p>
              <p className="my-0 content-text footer-lh fw-normal">FAQ</p>
              <p className="my-0 content-text footer-lh fw-normal">Đào tạo</p>
            </CCol>
          </CRow>
        </CContainer>
      </CCol>

      <CCol xs={12} className="footer-end py-4">
        <CContainer className="d-flex justify-content-between ">
          <div>
            <span className="me-1 end-text">
              <CIcon icon={cilCopyright} size="sm" /> Copyright 2022.
            </span>
            <a
              className="end-text text-decoration-none"
              href="http://vream.vn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Phát triển bởi Công ty cổ phần Quản lý Tài Sản BĐS Việt Nam
            </a>
          </div>
          <div className="social-links d-flex justify-content-start">
            {iconArr.map((item) => (
              <a key={item.id} href={item.url} rel="noopener noreferrer" target="_blank" className="me-4">
                <CIcon icon={item.icon} size="xl" className="text-white" />
              </a>
            ))}
          </div>
        </CContainer>
      </CCol>
    </CRow>
  );
};

export default React.memo(TheFooter);
