import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from 'reducers';
import { IRewardHistory } from 'shared/model/reward.model';
import { ISecondaryReward } from 'shared/model/secondaryReward.model';
import { IInitialState, IResponse } from 'shared/shared-interfaces';
import { getListingTransactions, getRewardHistory, updateEntity } from './secondaryReward.api';

const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const secondaryRewardsAdapter = createEntityAdapter<ISecondaryReward>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'secondaryRewardsSlice',
  initialState: secondaryRewardsAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getListingTransactions.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IResponse<ISecondaryReward[]>>>
    ) => {
      secondaryRewardsAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getListingTransactions.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IRewardHistory>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [getRewardHistory.fulfilled.type]: (state, { payload }: PayloadAction<ISecondaryReward>) => {
      secondaryRewardsAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.loading = false;
    },
    [getRewardHistory.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const secondaryRewardSelectors = secondaryRewardsAdapter.getSelectors<RootState>(
  (state) => state.secondaryReward
);

const { selectById } = secondaryRewardsAdapter.getSelectors();
const getSecondaryRewardState = (rootState: RootState) => rootState.secondaryReward;

export const selectEntityById = (id: string) => {
  return createSelector(getSecondaryRewardState, (state) => selectById(state, id));
};
