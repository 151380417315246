import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { AffType } from 'shared/enumeration/affType';
import axios from '../../../../../shared/config/axios-interceptor';
import { Departments } from '../../../../../shared/enumeration/departments';
import { INewUser, IUser } from '../../../../../shared/model/user.model';
import { IUserFilter } from './HeadManagament/HeadManagament';

const prefix = 'users';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IUserFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IUser[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IUser>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewUser, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IUser, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IUser>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUpdateHead {
  id: string;
  departmentType: Departments;
}

export const updateHeadDept = createAsyncThunk(
  `update-${prefix}-head-department`,
  async (body: IUpdateHead, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IUpdateHead>(`${prefix}/${id}/head-department`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IAffUsersFilter {
  affType: AffType[];
}

export const getAffEntities = createAsyncThunk(`get-affiliate-${prefix}`, async (fields: IAffUsersFilter, thunkAPI) => {
  try {
    let params = pickBy({...fields, affType: fields.affType.join(',')})
    return await axios.get<IUser[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
