import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { VerifyType } from '../../../shared/enumeration/verifyType';
import { IUser } from '../../../shared/model/user.model';

export interface ILoginForm {
  username: string;
  password: string;
  rememberMe: boolean;
}

export interface IRegisterForm {
  login: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  email: string;
  idCard: string;
}

export const login = createAsyncThunk(`authenticate`, async (body: ILoginForm, thunkAPI) => {
  try {
    const { data } = await axios.post(`authenticate`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const register = createAsyncThunk(`register`, async (body: IRegisterForm, thunkAPI) => {
  try {
    const { data } = await axios.post(`register`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateAccount = createAsyncThunk(`updateAccount`, async (body: IUser, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IUser>(`users/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const account = createAsyncThunk(`account`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get(`account`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface ISendResetPasswordKey {
  phone?: string;
  email?: string;
  isPhone: boolean;
}

export const sendResetPasswordKey = createAsyncThunk(
  `send-reset-password-key`,
  async (body: ISendResetPasswordKey, thunkAPI) => {
    try {
      const { data } = await axios.post(`account/password/reset/init`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IResetPassword extends ISendResetPasswordKey {
  newPassword: string;
  resetKey: string;
}

export const resetPassword = createAsyncThunk(`reset-password`, async (body: IResetPassword, thunkAPI) => {
  try {
    const { data } = await axios.post(`account/password/reset/finish`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IChangePassword {
  currentPassword: string;
  newPassword: string;
}

export const changePassword = createAsyncThunk(`change-password`, async (body: IChangePassword, thunkAPI) => {
  try {
    const { data } = await axios.post(`account/password/change`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface ISendOtpToUpdateSecurityInfo {
  newPhone?: string;
  newEmail?: string;
}

export const sendOtpToUpdateSecurityInfo = createAsyncThunk(
  `send-otp-security`,
  async (body: ISendOtpToUpdateSecurityInfo, thunkAPI) => {
    try {
      const { data } = await axios.post(`account/security/init`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IUpdateSecurityInfo {
  currentPhoneOtp?: string;
  newPhoneOtp?: string;
  currentEmailOtp?: string;
  newEmailOtp?: string;
  submitBtnClicked?: VerifyType;
}

export const updateSecurityInfo = createAsyncThunk(
  `update-security-info`,
  async (body: IUpdateSecurityInfo, thunkAPI) => {
    try {
      const { data } = await axios.post<IUser>(`account/security/finish`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IResetWalletAddress {
  walletAddress: string;
}

export const resetWalletAddress = createAsyncThunk(
  `send-otp-reset-wallet-address`,
  async (body: IResetWalletAddress, thunkAPI) => {
    try {
      const { data } = await axios.post(`account/reset-wallet-address`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface ICheckWalletAddress {
  walletAddress: string;
  otp: string;
}

export const checkWalletAddress = createAsyncThunk(
  `check-wallet-address`,
  async (body: ICheckWalletAddress, thunkAPI) => {
    try {
      return await axios.post(`account/check-wallet-address`, pickBy(body));
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const loginKeyCloak = createAsyncThunk(`authenticate-keycloak`, async (body: ILoginForm, thunkAPI) => {
  try {
    const { data } = await axios.post(`sso/anft`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
