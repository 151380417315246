import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Departments } from '../../../../shared/enumeration/departments';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const ProductManagement = React.lazy(() => import('./Overview'));
const ProductRegistration = React.lazy(
  () => import('../../FO/AccountManagement/RegistrationManagement/RegisteredProducts/ListingRegistration/Registration')
);
const ListingsRegistrationLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, SystemRoles.EXTERNAL_STAFF]}
        department={Departments.DEPT_EXPLOITATION}
      >
        <ProductManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: ':type/:id/product-register/:step',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]} department={Departments.DEPT_EXPLOITATION}>
        <ProductRegistration />
      </ProtectedComponent>
    ),
  },
  {
    path: ':type/:projectId/product-register/:id/:step',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]} department={Departments.DEPT_EXPLOITATION}>
        <ProductRegistration />
      </ProtectedComponent>
    ),
  },
  // {
  //   path: ':type/:projectId/product-register/:id/:step/tasks/:taskId',
  //   element: (
  //     <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
  //       <ProductRegistration />
  //     </ProtectedComponent>
  //   ),
  // },
  // {
  //   path: ':type/:id/product-register/:step/tasks/:taskId',
  //   element: (
  //     <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
  //       <ProductRegistration />
  //     </ProtectedComponent>
  //   ),
  // },
  { path: '*', element: <Navigate to="/404" /> },
];

const ProductRegistrationRoutes = () => useRoutes(ListingsRegistrationLayout);
export default ProductRegistrationRoutes;
