import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { AffType } from 'shared/enumeration/affType';
import { Status } from 'shared/enumeration/status';
import axios from '../../../../shared/config/axios-interceptor';
import { IBankAccount, INewBankAccount } from '../../../../shared/model/bank.model';
import { IParams } from '../../../../shared/shared-interfaces';

const affPrefix = 'external/bank-accounts';
const prefix = 'bank-accounts';

interface IGetBankAccountsParams extends IParams {
  type: AffType,
  status?: Status
}

export const getEntities = createAsyncThunk(`get-all-company-${affPrefix}`, async (fields: IGetBankAccountsParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<IBankAccount[]>(`${affPrefix}`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-company-${affPrefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IBankAccount>(`${affPrefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentEntity = createAsyncThunk(`get-current-head-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IBankAccount>(`${prefix}/current`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateCurrentEntity = createAsyncThunk(
  `update-current-head-${prefix}`,
  async (body: IBankAccount, thunkAPI) => {
    try {
      const { data } = await axios.put<IBankAccount>(`${prefix}/current`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createCurrentEntity = createAsyncThunk(
  `create-current-head-${prefix}`,
  async (body: INewBankAccount, thunkAPI) => {
    try {
      const { data } = await axios.post<IBankAccount>(`${prefix}/current`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
