import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { getAddress, getProvider, getProviderLogin, getSigner, proceedTransaction } from './wallet.api';

interface IWalletState {
  signerAddress: string | undefined;
  getSignerAddressSuccess: boolean;

  signer: ethers.providers.JsonRpcSigner | null;
  getSignerSuccess: boolean;

  getProviderLoginSuccess: boolean;

  provider: undefined | ethers.providers.Web3Provider;
  providerErrorMessage: null | string;

  loading: boolean;
  errorMessage: string | null;

  proceedTransactionSuccess: boolean;
}

const initialState: IWalletState = {
  signerAddress: undefined,
  getSignerAddressSuccess: false,
  signer: null,
  getSignerSuccess: false,
  getProviderLoginSuccess: false,
  provider: undefined,
  loading: false,
  errorMessage: null,
  providerErrorMessage: null,
  proceedTransactionSuccess: false,
};

const walletSlice = createSlice({
  name: 'walletSlice',
  initialState,
  reducers: {
    fetching(state) {
      state.loading = true;
    },
    reset(state) {
      state.signerAddress = '';
      state.getSignerAddressSuccess = false;
      state.signer = null;
      state.getSignerSuccess = false;
      state.getProviderLoginSuccess = false;
      state.loading = false;
      state.errorMessage = null;
      state.providerErrorMessage = null;
    },
    softReset(state) {
      state.getSignerAddressSuccess = false;
      state.getSignerSuccess = false;
      state.getProviderLoginSuccess = false;
      state.loading = false;
      state.errorMessage = null;
      state.proceedTransactionSuccess = false;
    },
    resetSigner(state) {
      state.signer = null;
      state.getSignerSuccess = false;
      state.signerAddress = '';
      state.getSignerAddressSuccess = false;
    },
  },
  extraReducers: {
    [getProviderLogin.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.getProviderLoginSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getProviderLogin.rejected.type]: (state, { payload }) => {
      state.getProviderLoginSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getSigner.fulfilled.type]: (state, { payload }: PayloadAction<ethers.providers.JsonRpcSigner>) => {
      state.signer = payload;
      state.getSignerSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getSigner.rejected.type]: (state, { payload }) => {
      state.getSignerSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getAddress.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.signerAddress = payload;
      state.getSignerAddressSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getAddress.rejected.type]: (state, { payload }) => {
      state.getSignerAddressSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getProvider.fulfilled.type]: (state, { payload }: PayloadAction<ethers.providers.Web3Provider>) => {
      state.provider = payload;
      state.loading = false;
    },
    [getProvider.rejected.type]: (state, { payload }) => {
      state.providerErrorMessage = payload?.message;
      state.loading = false;
    },
    [proceedTransaction.fulfilled.type]: (state, { payload }) => {
      state.proceedTransactionSuccess = true;
      state.loading = false;
    },
    [proceedTransaction.rejected.type]: (state, { payload }) => {
      state.errorMessage = payload?.message;
      state.proceedTransactionSuccess = false;
      state.loading = false;
    },
  },
});

export default walletSlice.reducer;
export const { fetching, reset, softReset, resetSigner } = walletSlice.actions;
