import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDurationRisk } from '../../../../shared/model/listingType.model';
import { createEntity, getEntity, updateEntity } from './successStage.api';

interface IInitialState {
  durationRisk: IDurationRisk[];
  fetchEntitySuccess: boolean;
  updateEntitySuccess: boolean;
  loading: boolean;
  errorMessage: string | null;
}

const initialState: IInitialState = {
  durationRisk: [],
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  loading: false,
  errorMessage: null,
};

const { actions, reducer } = createSlice({
  name: 'successStageSlice',
  initialState: initialState,
  reducers: {
    fetching(state) {
      state.loading = true;
    },
    resetAll(state) {
      state.loading = false;
      state.durationRisk = [];
      state.fetchEntitySuccess = false;
      state.updateEntitySuccess = false;
      state.errorMessage = null;
    },
    resetEntity(state) {
      state.updateEntitySuccess = false;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IDurationRisk[]>) => {
      state.durationRisk = payload;
      state.fetchEntitySuccess = true;
      state.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IDurationRisk[]>) => {
      state.durationRisk = payload;
      state.updateEntitySuccess = true;
      state.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IDurationRisk[]>) => {
      state.durationRisk = payload;
      state.updateEntitySuccess = true;
      state.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.updateEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;
