import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const Revenue = React.lazy(() => import('./Revenue'));
const RevenueStage = React.lazy(() => import('./RevenueStage'));
const RevenueAllocation = React.lazy(() => import('./RevenueAllocation/RevenueAllocation'));
const RevenueAllocationUpdate = React.lazy(() => import('./RevenueAllocation/RevenueAllocationUpdate'));

const RevenueLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <Revenue />
      </ProtectedComponent>
    ),
  },
  {
    path: '/overview',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <Revenue />
      </ProtectedComponent>
    ),
  },
  {
    path: '/stage',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <RevenueStage />
      </ProtectedComponent>
    ),
  },
  {
    path: '/allocation',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <RevenueAllocation />
      </ProtectedComponent>
    ),
  },
  {
    path: '/allocation/create',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <RevenueAllocationUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: '/allocation/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <RevenueAllocationUpdate />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const RevenueRoutes = () => useRoutes(RevenueLayout);
export default RevenueRoutes;
