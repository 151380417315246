import { CButton, CCarousel, CCarouselItem, CCol, CContainer, CRow } from '@coreui/react-pro';
import { useEffect, useRef, useState } from 'react';
import coverImg from '../../../../assets/img/cover-img.png';
import { useRouter } from '../../../../shared/utils/hooks/useRouter';
import './index.scss';

const useCoverComponent = () => {
  const { location, redirectView } = useRouter();
  const currentLocation = location.pathname;
  const isDashboardView: boolean = currentLocation === '/';

  const filterRef = useRef<HTMLDivElement>(null);

  // Comment giải thích height ở đây làm gì
  // để lấy height của filtercomponent
  const [filterHeight, setFilterHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    setFilterHeight(filterRef.current?.clientHeight);
  }, [filterRef]);

  // const coverImageClasses = isDashboardView ? 'cover-dashboard-image' : 'cover-listing-image';
  // const coverSearchClasses = isDashboardView ? 'cover-dashboard-search' : 'cover-listing-search';

  const listContent = [
    {
      id: 0,
      content: 'Tối ưu dòng vốn cho các công ty kinh doanh BĐS',
    },
    {
      id: 1,
      content: 'Sinh lời ngay cho các chủ tài sản BĐS (công ty, cá nhân)',
    },
    {
      id: 2,
      content: 'Tham gia kinh doanh BĐS chỉ cần số vốn nhỏ',
    },
  ];

  const Component = () => (
    <>
      <CContainer
        fluid
        className={`cover-background-image cover-dashboard-image`}
        style={{ backgroundImage: `url(${coverImg})` }}
      >
        <div className="image-container position-absolute"></div>
      </CContainer>
      <CContainer fluid className={`cover-container mt-5 mb-3 cover-dashboard-search px-0`}>
        <div className="dashboard-title-container">
          <CRow className="justify-content-center ">
            <CCol
              md={12}
              className={`mb-5 title-wrapper d-flex flex-column align-items-center text-white ${
                isDashboardView ? 'justify-content-center' : ''
              }`}
            >
              <h1 className="text-uppercase text-center fw-normal letter-spacing-lg">Nền tảng Vận hành và </h1>
              <h1 className="text-uppercase text-center fw-normal letter-spacing-lg">Khai thác Tài sản Bất động sản</h1>
              <br />

              <CCarousel indicators className="mb-3">
                {listContent.map((item, idx) => (
                  <CCarouselItem key={`cover-content-${idx}`}>
                    <h4 className="text-center fw-normal">{item.content}</h4>
                  </CCarouselItem>
                ))}
              </CCarousel>

              <br />
              <CButton
                className="btn-listing-register my-1 text-anft-gradiant border-0"
                onClick={redirectView('/FO/registration-management/products')}
              >
                Đăng ký tài sản BĐS
              </CButton>
            </CCol>
            {/* <CCol md={10} className="filter-wrapper mt-5">
              <FilterComponent />
            </CCol> */}
          </CRow>
        </div>
      </CContainer>
    </>
  );

  return { filterHeight, Component };
};

export default useCoverComponent;
