import { combineReducers } from 'redux';
import container from './components/containers/reducer';
import authentication from './components/modules/auth/auth.reducer';
import wallet from './components/modules/blockchain/wallet.reducer';
import category from './components/modules/BO/Category/category.reducer';
import commissionConfigs from './components/modules/BO/CommissionConfigs/commissionConfigs.reducer';
import externalStaffRegistration from './components/modules/BO/RequestsManagement/ExternalStaffApproval/externalStaffRegistration.reducer';
import payment from './components/modules/BO/RequestsManagement/PaymentApproval/paymentApproval.reducer';
import successStage from './components/modules/BO/SuccessStageConfig/successStage.reducer';
import taskConfig from './components/modules/BO/TaskConfig/taskConfig.reducer';
import taskAssign from './components/modules/BO/TasksManagement/taskAssign.reducer';
import users from './components/modules/BO/UsersManagement/Internal/usersManagement.reducer';
import rewards from './components/modules/FO/AccountManagement/OwnedListings/Exchange/rewards.reducer';
import publicListing from './components/modules/FO/Exchanges/listing.reducer';
import bankAccount from './components/modules/shared/bank-account/bankAccount.reducer';
import miniStages from './components/modules/shared/mini-stage/miniStages.reducer';
import provinces from './components/modules/shared/provinces/provinces.reducer';
import productRegistration from './components/modules/shared/requests/productRegistration.reducer';
import projectRegistration from './components/modules/shared/requests/projectRegistration.reducer';
import stage from './components/modules/shared/stage/stages.reducer';
import tradeOffice from './components/modules/shared/trade-office/tradeOffice.reducer';
import revenue from './components/modules/BO/Revenue/revenue.reducer';
import revenueAllocation from './components/modules/BO/Revenue/RevenueAllocation/revenueAllocation.reducer';
import banks from 'components/modules/shared/banks/banks.reducer';
import tradeRequirements from 'components/modules/shared/requirements/tradeRequirements.reducer';
import paymentRequest from 'components/modules/BO/PaymentRequest/paymentRequest.reducer';
import secondaryReward from 'components/modules/FO/AccountManagement/ExploitingListings/OwnershipRegisteredListings/secondaryReward.reducer';
import franchisePaymentRequest from 'components/modules/FO/AccountManagement/OwnedListings/Franchise/franchisePayment.reducer';
import dashboard from 'components/modules/FO/Dashboard/dashboard.reducer';

const rootReducer = combineReducers({
  container,
  category,
  publicListing,
  productRegistration,
  provinces,
  successStage,
  projectRegistration,
  rewards,
  wallet,
  authentication,
  stage,
  miniStages,
  taskConfig,
  users,
  taskAssign,
  externalStaffRegistration,
  bankAccount,
  payment,
  tradeOffice,
  commissionConfigs,
  revenue,
  revenueAllocation,
  banks,
  tradeRequirements,
  paymentRequest, // Head department request withdraw revenue
  secondaryReward,
  franchisePaymentRequest,
  dashboard
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
