import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../../reducers';
import { IRevenue } from '../../../../shared/model/revenue.model';
import { IInitialState, IResponse } from '../../../../shared/shared-interfaces';
import { getCurrentEntity, getEntities, getNearestRevenues, getTotalRevenues, updateEntity } from './revenue.api';

interface IRevenueInitialState extends IInitialState {
  currentRevenueStage: IRevenue | null;
  totalRevenues: IRevenue | null;
  nearestRevenues: IRevenue[];
}

const initialState: IRevenueInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  currentRevenueStage: null,
  totalRevenues: null,
  nearestRevenues: [],
};

export const revenueAdapter = createEntityAdapter<IRevenue>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'revenueSlice',
  initialState: revenueAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<IRevenue[]>>>) => {
      revenueAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IRevenue>) => {
      state.initialState.currentRevenueStage = payload;
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IRevenue>) => {
      revenueAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [getTotalRevenues.fulfilled.type]: (state, { payload }: PayloadAction<IRevenue>) => {
      state.initialState.totalRevenues = payload;
      state.initialState.loading = false;
    },
    [getTotalRevenues.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
    [getNearestRevenues.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<IRevenue[]>>>) => {
      state.initialState.nearestRevenues = payload.data.results;
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getNearestRevenues.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const revenueSelectors = revenueAdapter.getSelectors<RootState>((state) => state.revenue);

const { selectById } = revenueAdapter.getSelectors();
const getRevenueState = (rootState: RootState) => rootState.revenue;

export const selectEntityById = (id: string) => {
  return createSelector(getRevenueState, (state) => selectById(state, id));
};
