import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../shared/config/axios-interceptor';
import { IRevenue } from '../../../../shared/model/revenue.model';
import { IParams } from '../../../../shared/shared-interfaces';

const prefix = 'revenues';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IRevenue[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.put<IRevenue>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentEntity = createAsyncThunk(`get-current-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IRevenue>(`${prefix}/current-revenue`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getTotalRevenues = createAsyncThunk(`get-total-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IRevenue>(`${prefix}/total-closed`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getNearestRevenues = createAsyncThunk(`get-nearest-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IRevenue[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
