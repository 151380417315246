import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useRouter } from '../../../shared/utils/hooks/useRouter';
import { account } from '../../modules/auth/auth.api';
import { fetching } from '../../modules/auth/auth.reducer';
import TheContent from './TheContent';
import TheFooter from './TheFooter';
import TheHeader from './TheHeader';


const TheLayout = () => {
  const { location } = useRouter();
  const currentLocation = location.pathname;
  const isBOView = currentLocation.includes('/BO');

  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
    let tempToken = token;
    if (!tempToken) {
      tempToken = localStorage.getItem('authentication_token');
    }

    if (tempToken) {
      dispatch(fetching());
      dispatch(account());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  
  return (
    <div>
      <div className={isBOView ? '' : 'wrapper d-flex flex-column min-vh-100'}>
        <TheHeader />
        <div className={isBOView ? '' : 'body flex-grow-1 overflow-hidden'}>
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};
export default TheLayout;
