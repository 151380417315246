import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const ProductManagement = React.lazy(() => import('./System/ProductManagement'));
const ProductUpdate = React.lazy(() => import('./System/ProductUpdate'));
const FranchiseListingsMngmt = React.lazy(
  () => import('../../FO/AccountManagement/OwnedListings/Franchise/FranchiseListings')
);
const PrimaryListings = React.lazy(() => import('./PrimaryListings'));
const SecondaryListings = React.lazy(() => import('./SecondaryListings'));
const Projects = React.lazy(() => import('./Projects'));

const ListingManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.INTERNAL_STAFF]}>
        <ProductManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/system-products',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.INTERNAL_STAFF]}>
        <ProductManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: 'system-products/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.INTERNAL_STAFF]}>
        <ProductUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: 'franchise-products',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <FranchiseListingsMngmt />
      </ProtectedComponent>
    ),
  },
  {
    path: 'primary-products',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <PrimaryListings />
      </ProtectedComponent>
    ),
  },
  {
    path: 'secondary-products',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <SecondaryListings />
      </ProtectedComponent>
    ),
  },
  {
    path: 'projects',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <Projects />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const ListingManagementRoutes = () => useRoutes(ListingManagementLayout);
export default ListingManagementRoutes;
