import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../../reducers';
import { IStage } from '../../../../shared/model/stage.model';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { getEntities, getEntity } from './stages.api';

const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const stageAdapter = createEntityAdapter<IStage>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'stageSlice',
  initialState: stageAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IStage[]>>) => {
      stageAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IStage>) => {
      stageAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const stageSelectors = stageAdapter.getSelectors<RootState>((state) => state.stage);

const { selectById } = stageAdapter.getSelectors();
const getStageState = (rootState: RootState) => rootState.stage;

export const selectEntityById = (id: string) => {
  return createSelector(getStageState, (state) => selectById(state, id));
};
