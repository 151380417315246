import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { IFranchisePaymentRequest } from 'shared/model/franchisePaymentRequest.model';
import { IParams } from 'shared/shared-interfaces';
import axios from '../../../../../../shared/config/axios-interceptor';

const prefix = 'franchise-payment-requests';

export interface IPaymentRequestFilter extends IParams {
  listingId: string;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IPaymentRequestFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IFranchisePaymentRequest[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUpdateFranchisePaymentRequest {
  requestIds: string[];
}

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: IUpdateFranchisePaymentRequest, thunkAPI) => {
    try {
      const { data } = await axios.post<IFranchisePaymentRequest[]>(`${prefix}/approve`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface INewFranchisePaymenRequest {
  listingId: string;
  count: number | undefined;
}

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewFranchisePaymenRequest, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
