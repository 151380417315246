import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../../shared/config/axios-interceptor';
import { RequestStatus } from '../../../../../shared/enumeration/requestStatus';
import { Status } from '../../../../../shared/enumeration/status';
import { IDistrictsAddress, IProvincesAddress } from '../../../../../shared/model/provinces.model';
import { ITaskConfig } from '../../../../../shared/model/taskConfig.model';
import { IUser } from '../../../../../shared/model/user.model';
import { IParams } from '../../../../../shared/shared-interfaces';
import { mappingMiniStagetoArray } from '../../../../../shared/utils/utils';

const prefix = 'registrations';

interface IRegisteredProvinceAddress extends IProvincesAddress {
  configId: string;
}

interface IRegisteredDistrictAddress extends IDistrictsAddress {
  configId: string;
}

export interface IExternalStaffRegistration extends IUser {
  registrationDate: string;
  registrationStatus: RequestStatus;
  registrationConfigs: ITaskConfig[];
  registeredProvinces: IRegisteredProvinceAddress[];
  registeredDistricts: IRegisteredDistrictAddress[];
}

export const getEntities = createAsyncThunk(`get-all-registered-external-staff`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IExternalStaffRegistration[]>(`${prefix}/department`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-one-registered-external-staff`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IExternalStaffRegistration>(`${prefix}/${id}/detail`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface IRegistrationWithConfigs {
  id: string;
  registrationConfigs: ITaskConfig[];
}

interface IGetRegistrationConfig {
  id: string;
  status?: RequestStatus;
}

export const getEntityConfigs = createAsyncThunk(
  `get-one-registered-external-staff-config`,
  async (body: IGetRegistrationConfig, thunkAPI) => {
    const { id, status } = body;
    const params = pickBy({ status });
    try {
      const registrationConfigs = await axios.get<ITaskConfig[]>(`${prefix}/${id}/config`, { params });
      const registrationConfigsWithCompleteInfo = await mappingMiniStagetoArray(registrationConfigs.data);
      const result: IRegistrationWithConfigs = {
        id,
        registrationConfigs: registrationConfigsWithCompleteInfo,
      };
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IExternalStaffApproval {
  userId: string;
  status: RequestStatus;
}

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IExternalStaffApproval, thunkAPI) => {
  try {
    const { userId } = body;
    const { data } = await axios.put<IExternalStaffApproval>(`${prefix}/${userId}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface IUpdateExternalStaffStatus {
  id: string;
  status: Status;
}

export const updateExternalStaffStatus = createAsyncThunk(
  `update-one-external-staff-status`,
  async (body: IUpdateExternalStaffStatus, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IUpdateExternalStaffStatus>(`users/${id}/status`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const withdrawExternalActivity = createAsyncThunk(
  `withdraw-one-external-activity`,
  async (configId: string, thunkAPI) => {
    try {
      const { data } = await axios.put<IUpdateExternalStaffStatus>(`${prefix}/${configId}/withdraw`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
