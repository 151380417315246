import { createAsyncThunk } from '@reduxjs/toolkit';
import { BigNumber, ethers } from 'ethers';
import { pickBy } from 'lodash';
import { MessageType } from 'shared/enumeration/messageType';
import { BLOCKCHAIN_NETWORK, _window } from '../../../shared/config/constants';
import { promptUserToSwitchChain } from './helper';
import { Validation } from './typechain';

export const getProviderLogin = createAsyncThunk(
  'getProviderLogin',
  async (provider: ethers.providers.Web3Provider, thunkAPI) => {
    try {
      await provider.send('eth_requestAccounts', []);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSigner = createAsyncThunk('getSigner', async (provider: ethers.providers.Web3Provider, thunkAPI) => {
  try {
    const result = provider.getSigner();
    return result;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAddress = createAsyncThunk('getAddress', async (signer: ethers.providers.JsonRpcSigner, thunkAPI) => {
  try {
    const result = await signer.getAddress();
    return result;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const INVALID_NETWORK_ERR =
  `Mạng không hợp lệ. Vui lòng vào Metamask và chuyển sang ${BLOCKCHAIN_NETWORK.chainName} để sử dụng`;

export const getProvider = createAsyncThunk('getProvider', async (_, thunkAPI) => {
  try {
    if (!_window.ethereum)
      throw Error('Ethereum chưa được khởi tạo. Vui lòng cài đặt ví Ethereum trong trình duyệt của bạn');
    const provider = new ethers.providers.Web3Provider(_window.ethereum);
    const { chainId } = await provider.getNetwork();
    const providerChainIdBN = BigNumber.from(chainId);
    const networkChainIdBN = BigNumber.from(BLOCKCHAIN_NETWORK.chainId);
    if (!providerChainIdBN.eq(networkChainIdBN)) {
      promptUserToSwitchChain();
      throw Error(INVALID_NETWORK_ERR);
    }
    return provider;
  } catch (error) {console.log(error)
    return thunkAPI.rejectWithValue(error);
  }
});

export interface IProceedTxBody {
  instance: Validation;
  type: MessageType;
  sellPrice?: number;
  rentPrice?: number;
  otp?: string;
  userId?: string;
  listingId?: string;
}

export const proceedTransaction = createAsyncThunk('proceedTransaction', async (body: IProceedTxBody, thunkAPI) => {
  const { instance, ...rest } = body;
  try {
    const tx = await instance.sendMessage(JSON.stringify(pickBy(rest)));
    return await tx.wait(1);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
