import PaymentRequestRoutes from 'components/modules/BO/PaymentRequest/routes';
import TradeOfficesRoutes from 'components/modules/BO/TradeOfficeManagement/routes';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import AuthRoutes from './components/modules/auth/routes';
import CategoryRoutes from './components/modules/BO/Category/routes';
import CommissionConfigsRoutes from './components/modules/BO/CommissionConfigs/routes';
import ListingManagementRoutes from './components/modules/BO/ListingManagement/routes';
import ProductRegistrationRoutes from './components/modules/BO/ProductDraftManagement/routes';
import RequestsRoutes from './components/modules/BO/RequestsManagement/routes';
import RevenueRoutes from './components/modules/BO/Revenue/routes';
import TaskConfigRoutes from './components/modules/BO/TaskConfig/routes';
import TasksManagementRoutes from './components/modules/BO/TasksManagement/routes';
import UsersManagementRoutes from './components/modules/BO/UsersManagement/routes';
import Listings from './components/modules/FO/Exchanges/routes';
import { RootState } from './reducers';
import { backOfficeRoles, Roles } from './shared/enumeration/roles';
import { useRouter } from './shared/utils/hooks/useRouter';

const BOLayout = React.lazy(() => import('./components/containers/BO/TheLayout'));

const AccountLayout = React.lazy(() => import('./components/containers/Profile/TheLayout'));

const DashboardFO = React.lazy(() => import('./components/modules/FO/Dashboard/Dashboard'));
const Planning = React.lazy(() => import('./components/modules/FO/Planning/Planning'));

// Error pages

const Page404 = React.lazy(() => import('./components/modules/Page404'));
const Page500 = React.lazy(() => import('./components/modules/Page500'));
const Page403 = React.lazy(() => import('./components/modules/Page403'));

const ExternalStaffProfile = React.lazy(() => import('./components/modules/BO/Profile/External/ExternalStaffProfile'));
const HeadProfile = React.lazy(() => import('./components/modules/BO/Profile/Internal/HeadProfile'));
const UserProfile = React.lazy(() => import('./components/modules/BO/Profile/Internal/UserProfile'));

// const DummyBackOfficeHome = () => <div>Back Office Home</div>;

const BackOfficeProfile = () => {
  const { user } = useSelector((state: RootState) => state.authentication);

  switch (user?.role) {
    case Roles.EXTERNAL_STAFF:
      return <ExternalStaffProfile />;
    case Roles.HEAD:
      return <HeadProfile />;
    default:
      return <UserProfile />;
  }
};

const frontOffice: RouteObject[] = [
  { path: '/', element: <DashboardFO /> },
  { path: '/listings/*', element: <Listings /> },
  { path: '/planning', element: <Planning /> },
  { path: '/auth/*', element: <AuthRoutes /> },
  { path: '/FO/*', element: <AccountLayout /> },
  { path: '/404', element: <Page404 /> },
  { path: '/500', element: <Page500 /> },
  { path: '/403', element: <Page403 /> },
  {
    path: '/BO/*',
    element: <BOLayout />,
    caseSensitive: true,
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const backOffice: RouteObject[] = [
  { path: '/', element: <BackOfficeProfile /> },
  { path: 'revenue/*', element: <RevenueRoutes /> },
  { path: 'category/*', element: <CategoryRoutes /> },
  { path: 'requests-management/*', element: <RequestsRoutes /> },
  { path: 'users/*', element: <UsersManagementRoutes /> },
  { path: 'task-config/*', element: <TaskConfigRoutes /> },
  { path: 'tasks-management/*', element: <TasksManagementRoutes /> },
  { path: 'product-registration/*', element: <ProductRegistrationRoutes /> },
  { path: 'commision-configs/*', element: <CommissionConfigsRoutes /> },
  { path: 'office-management/*', element: <TradeOfficesRoutes /> },
  { path: 'payment-requests/*', element: <PaymentRequestRoutes /> },
  { path: 'listing-management/*', element: <ListingManagementRoutes /> },
  { path: '*', element: <Navigate to="/404" /> },
];

const FrontOfficeRender = () => useRoutes(frontOffice);

// const BackOfficeRender = () => useRoutes(backOffice);

const BackOfficeRender = () => {
  const backOfficeRoutes = useRoutes(backOffice);

  const { pathname: path } = useRouter().location;

  const { user } = useSelector((state: RootState) => state.authentication);

  const isBackOfficeUser = user ? backOfficeRoles.includes(user.role) : false;

  if (!isBackOfficeUser) {
    return <Navigate to="/auth/login/FO" state={{ path }} />;
  }

  return backOfficeRoutes;
};

export { FrontOfficeRender, backOffice, frontOffice, BackOfficeRender };
