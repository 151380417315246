import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Departments, PrivateInternalTasks } from '../../../../shared/enumeration/departments';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const ProductsManagement = React.lazy(() => import('./ProductType/Products'));
const UpdateProduct = React.lazy(() => import('./ProductType/ProductUpdate'));
const ProjectsManagement = React.lazy(() => import('./ProjectType/Projects'));
const UpdateProject = React.lazy(() => import('./ProjectType/ProjectUpdate'));
const ProductTypeStageConfig = React.lazy(() => import('../SuccessStageConfig/ProductTypeStageConfig'));

const CategoryLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.PRODUCT_TYPE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <ProductsManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/product',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.PRODUCT_TYPE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <ProductsManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/product/:id/update',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.PRODUCT_TYPE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <UpdateProduct />
      </ProtectedComponent>
    ),
  },
  {
    path: '/product/create',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.PRODUCT_TYPE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <UpdateProduct />
      </ProtectedComponent>
    ),
  },
  {
    path: '/project',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.PRODUCT_TYPE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <ProjectsManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/project/:id/update',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.PRODUCT_TYPE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <UpdateProject />
      </ProtectedComponent>
    ),
  },
  {
    path: '/project/create',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.PRODUCT_TYPE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <UpdateProject />
      </ProtectedComponent>
    ),
  },

  {
    path: '/stage-config',
    element: (
      <ProtectedComponent
        requiredPerms={[SystemRoles.ROLE_HEAD, PrivateInternalTasks.SUCCESS_STAGE_CONFIG]}
        department={Departments.DEPT_VALIDATION}
      >
        <ProductTypeStageConfig />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const CategoryRoutes = () => useRoutes(CategoryLayout);
export default CategoryRoutes;
