import { CContainer } from '@coreui/react-pro';
import { getSigner } from 'components/modules/blockchain/wallet.api';
import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { _window } from 'shared/config/constants';
import { FrontOfficeRender as FrontOfficeRoutes } from '../../../routes';
import { useRouter } from '../../../shared/utils/hooks/useRouter';
import useCoverComponent from '../../modules/FO/CoverComponents/CoverComponent';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  const { provider } = useSelector((state: RootState) => state.wallet);
  const dispatch = useDispatch();
  const { location } = useRouter();
  const currentLocation = location.pathname;
  const isBOView = currentLocation.includes('/BO');
  const { filterHeight } = useCoverComponent();

  const containerMinHeight = filterHeight ? filterHeight - 300 : 0;

  _window.ethereum?.on('accountsChanged', () => {
    if (provider) {
      dispatch(getSigner(provider));
    }
  });
  _window.ethereum?.on('chainChanged', (chainId: number) => {
    window.location.reload();
  });

  return (
    <CContainer fluid className='px-0'>
      <div style={isBOView ? {} : { minHeight: containerMinHeight }}>
        <Suspense fallback={loading}>
          <FrontOfficeRoutes />
        </Suspense>
      </div>
    </CContainer>
  );
};

export default React.memo(TheContent);
