import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { PaymentRequestStatus } from 'shared/enumeration/paymentRequestStatus';
import { IPaymentRequest } from 'shared/model/paymentRequest.model';
import { IParams } from 'shared/shared-interfaces';
import axios from '../../../../shared/config/axios-interceptor';

const prefix = 'payment-requests';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IPaymentRequest[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IPaymentRequest>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface IUpdatePaymentRequestInfo {
  id: string;
  bankAccountId: string;
}

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: IUpdatePaymentRequestInfo, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IPaymentRequest>(`${prefix}/${id}`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IUpdatePaymentRequestStatus {
  id: string;
  status: PaymentRequestStatus;
}

export const updateEntityStatus = createAsyncThunk(
  `update-one-${prefix}-status`,
  async (body: IUpdatePaymentRequestStatus, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IPaymentRequest>(`${prefix}/${id}/update-status`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
