import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ErrorBoundary from './components/containers/ErrorBoundary';
import FOLayout from './components/containers/FO/TheLayout';
import store from './store';

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ToastContainer
          position={toast.POSITION.TOP_LEFT}
          className="toastify-container"
          toastClassName="toastify-toast"
        />
          <Router>
            <FOLayout />
          </Router>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
