import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../../reducers';
import { IBankAccount } from '../../../../shared/model/bank.model';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { createCurrentEntity, getCurrentEntity, getEntities, getEntity, updateCurrentEntity } from './bankAccount.api';

interface IBankAccountInitialState extends IInitialState {
  currentBankAccount: IBankAccount | null;
}

const initialState: IBankAccountInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  currentBankAccount: null,
};

export const bankAccountAdapter = createEntityAdapter<IBankAccount>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'bankAccountSlice',
  initialState: bankAccountAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IBankAccount[]>>) => {
      bankAccountAdapter.setAll(state, payload.data);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      bankAccountAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [getCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      state.initialState.currentBankAccount = payload;
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const bankAccountSelectors = bankAccountAdapter.getSelectors<RootState>((state) => state.bankAccount);

const { selectById } = bankAccountAdapter.getSelectors();
const getBankAccountState = (rootState: RootState) => rootState.bankAccount;

export const selectEntityById = (id: string) => {
  return createSelector(getBankAccountState, (state) => selectById(state, id));
};
