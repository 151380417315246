import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../../reducers';
import { IProduct } from '../../../../shared/model/listing.model';
import { IInitialState, IResponse } from '../../../../shared/shared-interfaces';
import {
  assignListingFranchisee,
  changePublicShow,
  createEntity,
  getEntities,
  getEntity,
  getExploitingEntities,
  getListingSoldDate,
  getOwnedEntities,
  getSecondaryEntities,
  removeEntity,
  updateBusinessPrice,
  updateEntity,
  updateEntityBoundary,
  updateEntityCooperation,
  updateEntitySellStatus,
  updateEntityStatus,
  updateListingValue,
  updateSoldListingOption,
} from './productRegistration.api';

interface IProductRegistration extends IInitialState {
  entityId: string;
}

const initialState: IProductRegistration = {
  entityId: '',
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const productRegistrationAdapter = createEntityAdapter<IProduct>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'productRegistrationSlice',
  initialState: productRegistrationAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.entityId = '';
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<IProduct[]>>>) => {
      productRegistrationAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getSecondaryEntities.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IResponse<IProduct[]>>>
    ) => {
      productRegistrationAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getSecondaryEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.addOne(state, payload);
      state.initialState.entityId = payload.id;
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.entityId = payload.id;
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      productRegistrationAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [updateEntityBoundary.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntityBoundary.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateEntityStatus.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntityStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateEntityCooperation.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntityCooperation.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateListingValue.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateListingValue.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateBusinessPrice.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateBusinessPrice.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [changePublicShow.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [changePublicShow.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [assignListingFranchisee.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [assignListingFranchisee.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [getExploitingEntities.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IResponse<IProduct[]>>>
    ) => {
      productRegistrationAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getExploitingEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntitySellStatus.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntitySellStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [getOwnedEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<IProduct[]>>>) => {
      productRegistrationAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getOwnedEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateSoldListingOption.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.entityId = payload.id;
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateSoldListingOption.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [getListingSoldDate.fulfilled.type]: (state, { payload }: PayloadAction<IProduct>) => {
      productRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getListingSoldDate.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const productRegistrationSelectors = productRegistrationAdapter.getSelectors<RootState>(
  (state) => state.productRegistration
);

const { selectById } = productRegistrationAdapter.getSelectors();
const getProductRegistrationState = (rootState: RootState) => rootState.productRegistration;

export const selectEntityById = (id: string) => {
  return createSelector(getProductRegistrationState, (state) => selectById(state, id));
};
