import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const PaymentRequest = React.lazy(() => import('./PaymentRequest'));
const PaymentRequestDetail = React.lazy(
  () => import('../RequestsManagement/RevenueAllocationApproval/RevenueAllocationApproval')
);

const paymentRequestLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <PaymentRequest />
      </ProtectedComponent>
    ),
  },
  {
    path: '/:id/detail',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <PaymentRequestDetail />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const PaymentRequestRoutes = () => useRoutes(paymentRequestLayout);
export default PaymentRequestRoutes;
