import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../../reducers';
import { FavListing } from '../../../../shared/enumeration/favListing';
import { IListing } from '../../../../shared/model/listing.model';
import { IInitialState, IResponse } from '../../../../shared/shared-interfaces';
import {
  createEntity,
  getEntities,
  getEntity, getPrimaryEntities, getSecondaryEntities,
  IUpdateFavListing,
  removeEntity,
  updateEntity,
  updateFavEntity,
  updateFavEntities
} from './listing.api';


export type IUpdateFavEntities = { [key: string]: FavListing };
interface IListingState extends IInitialState {
  favEntitiesBatch: IUpdateFavEntities ;
}

const initialState: IListingState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  favEntitiesBatch: {}
};

export const publicListingAdapter = createEntityAdapter<IListing>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'publicListingSlice',
  initialState: publicListingAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    toggleFavorite(state, {payload}: PayloadAction<IUpdateFavListing>) {
      
      publicListingAdapter.updateOne(state, {
        id: payload.listingId,
        changes: { isFavorite: payload.option === FavListing.ADD ? true : false },
      });
      
      state.initialState.favEntitiesBatch[payload.listingId] = payload.option;
      
      
    }
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<IListing[]>>>) => {
      publicListingAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IListing>) => {
      publicListingAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IListing>) => {
      publicListingAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IListing>) => {
      publicListingAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      publicListingAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getSecondaryEntities.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IResponse<IListing[]>>>
    ) => {
      publicListingAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getSecondaryEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getPrimaryEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IResponse<IListing[]>>>) => {
      publicListingAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getPrimaryEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },

    [updateFavEntity.fulfilled.type]: (state, { payload }: PayloadAction<IUpdateFavListing>) => {
      publicListingAdapter.updateOne(state, {
        id: payload.listingId,
        changes: { isFavorite: payload.option === FavListing.ADD ? true : false },
      });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateFavEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateFavEntities.fulfilled.type]: (state) => {
      state.initialState.favEntitiesBatch = {};
    },
    [updateFavEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
    },
  },
});

export const { fetching, resetAll, resetEntity, toggleFavorite } = actions;
export default reducer;

export const publicListingSelectors = publicListingAdapter.getSelectors<RootState>((state) => state.publicListing);

const { selectById } = publicListingAdapter.getSelectors();
const getPublicListingState = (rootState: RootState) => rootState.publicListing;

export const selectEntityById = (id: string) => {
  return createSelector(getPublicListingState, (state) => selectById(state, id));
};
