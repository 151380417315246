import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { RequestStatus } from 'shared/enumeration/requestStatus';
import axios from '../../../../shared/config/axios-interceptor';
import { INewProject, IProduct, IProject } from '../../../../shared/model/listing.model';
import { IParams } from '../../../../shared/shared-interfaces';

const prefix = 'projects';
const publicPrefix = 'public/projects';

export const createEntity = createAsyncThunk(`register-${prefix}`, async (body: INewProject, thunkAPI) => {
  try {
    const { listingAttributes } = body;
    const pickByListingAttributes = listingAttributes.filter((attribute) => attribute.value);
    const newBody = { ...body, listingAttributes: pickByListingAttributes };
    const { data } = await axios.post<INewProject>(`${prefix}`, pickBy(newBody));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IProject, thunkAPI) => {
  try {
    const { listingAttributes, id } = body;
    const pickByListingAttributes = listingAttributes.filter((attribute) => attribute.value);
    const newBody = { ...body, listingAttributes: pickByListingAttributes };
    const { data } = await axios.put<IProject>(`${prefix}/${id}`, pickBy(newBody));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntities = createAsyncThunk(`get-all-registered-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IProject[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-one-registered-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IProduct>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-register`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUpdateProjectStatus {
  id: string;
  typeIds: Array<string>;
  status: RequestStatus;
}

export const updateEntityStatus = createAsyncThunk(
  `update-status-${prefix}`,
  async (body: IUpdateProjectStatus, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put<IProject>(`${prefix}/${id}/status`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPublicEntities = createAsyncThunk(`get-all-${publicPrefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IProject[]>(`${publicPrefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
