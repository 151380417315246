import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListing } from '../../../../shared/model/listing.model';
import { IListingFilter } from '../CoverComponents/SearchBoxComponent';

interface IDashboardState {
  dashboardFilterState: IListingFilter | null;
}

const initialState: IDashboardState = {
  dashboardFilterState: null,
};

export const dashboardAdapter = createEntityAdapter<IListing>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'dashboardSlice',
  initialState: initialState,
  reducers: {
    setDashboardFilterState(state, { payload }: PayloadAction<IListingFilter>) {
      state.dashboardFilterState = payload;
    },
    resetDashboardFilterState(state) {
      state.dashboardFilterState = null
    }
  },
  extraReducers: {},
});

export const { setDashboardFilterState, resetDashboardFilterState} = actions;
export default reducer;
