import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { SharedInternalTasks } from '../../../../shared/enumeration/departments';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const ExternalTaskUpdate = React.lazy(() => import('./external/TaskUpdate'));
const ExternalTasksOverview = React.lazy(() => import('./external/TasksOverview'));
const ExternalTasksAssign = React.lazy(() => import('./external/ProductOverview'));
const TasksRewardHistory = React.lazy(() => import('./external/TasksRewardHistory'));
const TasksRegistered = React.lazy(() => import('./external/TasksRegistered'));

const ProductRegistration = React.lazy(
  () => import('../../FO/AccountManagement/RegistrationManagement/RegisteredProducts/ListingRegistration/Registration')
);

const TasksManagement: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <ExternalTasksAssign />
      </ProtectedComponent>
    ),
  },
  {
    path: '/external',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_HEAD,
          SharedInternalTasks.ASSIGN_EXTERNAL_TASK,
          SharedInternalTasks.APPROVE_EXTERNAL_TASK,
        ]}
      >
        <ExternalTasksAssign />
      </ProtectedComponent>
    ),
  },
  {
    path: 'external/:id/update',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_HEAD,
          SharedInternalTasks.ASSIGN_EXTERNAL_TASK,
          SharedInternalTasks.APPROVE_EXTERNAL_TASK,
        ]}
      >
        <ExternalTaskUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: '/external-users',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
        <ExternalTasksOverview />
      </ProtectedComponent>
    ),
  },
  {
    path: '/external-users/:type/:projectId/product-register/:id/:step/tasks/:taskId',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
        <ProductRegistration />
      </ProtectedComponent>
    ),
  },
  {
    path: '/external-users/:type/:id/product-register/:step/tasks/:taskId',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
        <ProductRegistration />
      </ProtectedComponent>
    ),
  },
  {
    path: '/reward-history',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
        <TasksRewardHistory />
      </ProtectedComponent>
    ),
  },
  {
    path: '/registered',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
        <TasksRegistered />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const TasksManagementRoutes = () => useRoutes(TasksManagement);
export default TasksManagementRoutes;
