import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const PrimaryListing = React.lazy(() => import('./Primary/PrimaryListing'));
const SecondaryListing = React.lazy(() => import('./Secondary/SecondaryListing'));
const TradeOfficeSecondaryListings = React.lazy(() => import('./Secondary/TradeOfficeSecondaryListings'));
const ListingDetail = React.lazy(() => import('./ListingDetails'));

const ListingsLayout: RouteObject[] = [
  { path: '/primary', element: <PrimaryListing /> },
  { path: '/secondary', element: <SecondaryListing /> },
  { path: '/secondary/trade-office/:id', element: <TradeOfficeSecondaryListings /> },
  { path: '/primary/:id/detail', element: <ListingDetail /> },
  { path: '/secondary/:type/:id/detail', element: <ListingDetail /> },
  { path: '*', element: <Navigate to="/404" /> },
];

const Listings = () => useRoutes(ListingsLayout);
export { ListingsLayout };
export default Listings;
