import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../shared/config/axios-interceptor';
import { INewTaskConfig, ITaskConfig } from '../../../../shared/model/taskConfig.model';
import { IParams, IResponse } from '../../../../shared/shared-interfaces';
import { mappingMiniStagetoArray } from '../../../../shared/utils/utils';

const prefix = 'registration-configs';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const taskConfigResponse = await axios.get<IResponse<ITaskConfig[]>>(`${prefix}`, { params });
    const taskConfigsDataWithCompleteInfo = await mappingMiniStagetoArray(taskConfigResponse.data.results);
    taskConfigResponse.data.results = taskConfigsDataWithCompleteInfo
    return taskConfigResponse;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITaskConfig>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewTaskConfig, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ITaskConfig, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<ITaskConfig>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
