import { IExternalStaffRegistration } from 'components/modules/BO/RequestsManagement/ExternalStaffApproval/externalStaffRegistration.api';
import dayjs, { Dayjs } from 'dayjs';
import { uniq } from 'lodash';
import { getMiniStageById, uploadImage } from '../../components/modules/shared/shared.api';
import { ImageType } from '../../components/shared/image-uploading/ImgUpload';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, HTML_DATE_INPUT_FORMAT } from '../config/constants';
import { IMiniStage } from '../model/miniStage.model';
import { IBaseStage } from '../model/taskConfig.model';
import { IParams } from '../shared-interfaces';

const noMoreThanOneDot = (input: number | string) => {
  const str = input.toString();
  let commasCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '.') commasCount++;
    if (commasCount > 1) break;
  }
  return commasCount <= 1;
};

export const insertCommas = (input: number | undefined | string, decimals: number = 4) => {
  if (typeof input === 'undefined') return '';
  if (!noMoreThanOneDot(input)) return '';
  const parts = input.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) parts[1] = parts[1].substring(0, decimals); // Only take the first 4 decimals
  return parts.join('.');
};

export const unInsertCommas = (input: string, decimals: number = 4) => {
  const parts = input.split('.');
  parts[0] = parts[0].replaceAll(',', '');
  if (parts[1]) parts[1] = parts[1].substring(0, decimals); // Only take the first 4 decimals
  return parts.join('.');
};

export const formatLocalDate = (input: string | Date | undefined): string => {
  return dayjs(input).format(APP_LOCAL_DATE_FORMAT);
};

export const formatHTMLLocalDate = (input: string | Date | undefined): string => {
  return dayjs(input).format(HTML_DATE_INPUT_FORMAT);
};

export const formatLocalDatetime = (input: string | Date | undefined): string => {
  return dayjs(input).format(APP_DATE_FORMAT);
};

export const returnPercentageOfX = (x: number, percentage: number) => {
  return ((percentage * x) / 100).toFixed(4);
};

export const xIsWhatPercentOfY = (x: number, y: number) => {
  if (y === 0) return '0.00';
  return ((x / y) * 100).toFixed(2);
};

export const createIndexes = <T, G extends IParams>(data: T[], filter: G) => {
  const { page, size } = filter;
  return data.map((element, index) => ({
    ...element,
    index: page * size + index + 1,
  }));
};

interface IFraction {
  numerator: number;
  denominator: number;
}

const reduceFraction = (numerator: number, denominator: number): IFraction => {
  const fractionCal = (num1st: number, num2nd: number): number => {
    return num2nd ? fractionCal(num2nd, num1st % num2nd) : num1st;
  };
  const result = fractionCal(numerator, denominator);
  return { numerator: numerator / result, denominator: denominator / result };
};

export const calculateRatio = (firstNumb: number, secondNumb: number): IFraction => {
  const fraction = reduceFraction(firstNumb, secondNumb);
  if (fraction.numerator === 1) return fraction;
  const reduceDenominator = Math.round(fraction.denominator / fraction.numerator);
  return { numerator: 1, denominator: reduceDenominator };
};

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const getEllipsisTxt = (str: string, n = 5) => {
  if (str) {
    return str.length > n ? `${str.slice(0, n)}...${str.slice(str.length - n)}` : str;
  }
  return '';
};

export const handleUploadImage = async (imageArray: ImageType[], isPrivate: boolean) => {
  if (imageArray.length === 0) return '';
  try {
    const prevImage = imageArray.filter((item) => item.file === undefined).map((item) => item.dataURL);
    let imagePromises: Promise<string>[] = [];
    imagePromises = imageArray
      .filter((item) => item.file !== undefined)
      .map((image) => {
        return isPrivate ? uploadImage(image.file!) : uploadImage(image.file!);
      });

    const imagesUrl = await Promise.all(imagePromises);
    const newImageArr = prevImage && prevImage.length > 0 ? [...prevImage, ...imagesUrl] : [...imagesUrl];
    const formatImagesUrl = JSON.stringify(newImageArr);
    return formatImagesUrl;
  } catch (e) {
    throw Error('Error upload file');
  }
};

// This method checks if an array include multiple elements
export const includeMultiple = <T>(superset: Array<T> = [], ...subset: Array<T>): boolean => {
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0;
  });
};

const getMiniStageDetailById = async (stageId: string): Promise<IMiniStage> => {
  try {
    return await getMiniStageById(stageId);
  } catch (e) {
    throw Error('Error in getMiniStageDetailById');
  }
};

export const mappingMiniStagetoArray = async <T extends IBaseStage>(configsStage: T[]): Promise<T[]> => {
  if (configsStage.length === 0) return configsStage;
  try {
    const uniqStageIds = uniq(configsStage.map((item) => item.stageId));
    const miniStagePromises = uniqStageIds.map((item) => {
      return getMiniStageDetailById(item);
    });
    const miniStages = await Promise.all(miniStagePromises);
    const taskConfigsWithCompleteInfo = configsStage.map((config) => {
      const miniStage = miniStages.find((stage) => stage.id === config.stageId) as IMiniStage;
      return {
        ...config,
        miniStage,
      };
    });
    return taskConfigsWithCompleteInfo;
  } catch (e) {
    throw Error('Error in mappingMiniStagetoTaskConfig');
  }
};

export const checkIsLocalhost = (): boolean => {
  const isLocalHost =
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    Boolean(window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
  return isLocalHost;
};

interface IMoneyUnitTranslate {
  number: string;
  unit: string;
}

export const moneyUnitTranslateToObject = (input: number): IMoneyUnitTranslate => {
  const units = ['triệu', 'tỷ', 'nghìn tỷ', 'triệu tỷ'];
  const unit = Math.floor((input / 1.0e1).toFixed(0).toString().length);
  const r = unit % 3;
  const pair = Number('1.0e+' + (unit - r));
  const x = Math.abs(Number(input)) / Number(pair.toFixed(2));
  return {
    number: x.toFixed(2),
    unit: units[Math.floor(unit / 3) - 2],
  };
};

export const moneyUnitTranslateToString = (input: number, toFixed: number = 0): string => {
  const units = ['triệu', 'tỷ', 'nghìn tỷ', 'triệu tỷ'];
  const unit = Math.floor((input / 1.0e1).toFixed(0).toString().length);
  const r = unit % 3;
  const pair = Number('1.0e+' + (unit - r));
  const x = Math.abs(Number(input)) / Number(pair.toFixed(2));
  return `${x.toFixed(toFixed)} ${units[Math.floor(unit / 3) - 2]}`;
};

/**
 * Pick a date in the the future
 * Calculate difference between current date and the listing createdDate.
 * Unique element = difference % 86400
 * Future Date - (Current date + Unique Element) = Result
 * From the result % 5, we can display consistent dummy data
 *
 */
export const calEffectiveTime = (ownerShipDateEnd: Date) => {
  const currentDate = dayjs();
  const difference = dayjs(ownerShipDateEnd).unix() - currentDate.unix();

  const seconds = Math.floor(difference % 60);
  const minutes = Math.floor((difference / 60) % 60);
  const hours = Math.floor((difference / (60 * 60)) % 24);
  const days = Math.floor(difference / (60 * 60 * 24));
  return { days, hours, minutes, seconds };

  // console.log(result, 'result');
};

export const isSameOrAfter = (date: Dayjs, compareDate: Dayjs): boolean => {
  const isSame = date.isSame(compareDate, 'second');
  const isAfter = date.isAfter(compareDate);
  return isSame || isAfter;
};

export const isSameOrBefore = (date: Dayjs, compareDate: Dayjs): boolean => {
  const isSame = date.isSame(compareDate, 'second');
  const isBefore = date.isBefore(compareDate);
  return isSame || isBefore;
};

export const manualCaretPosition = (e: React.FormEvent<HTMLInputElement>) => {
  const caret = e.currentTarget.selectionStart;
  const element = e.currentTarget;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export const substringText = (text: string, maxLength: number) => {
  const lengthOfText = text.length;
  if (lengthOfText > maxLength) {
    const substring = text.substring(0, maxLength);
    const indexOfLastSpace = substring.lastIndexOf(' ');
    return `${text.substring(0, indexOfLastSpace)}...`;
  } else {
    return text;
  }
};

export const returnExternalStaffName = (user: IExternalStaffRegistration) => {
  return user.fullName || user.agencyName || `${user.lastName} ${user.firstName}`
}