import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { IRewardHistory } from 'shared/model/reward.model';
import { ISecondaryReward } from 'shared/model/secondaryReward.model';
import { IResponse } from 'shared/shared-interfaces';
import axios from '../../../../../../shared/config/axios-interceptor';
import { IQueryHistoryParam, IRewardFilter } from '../../OwnedListings/Exchange/rewards.api';

const prefix = 'rewards';

export const getListingTransactions = createAsyncThunk(
  `get-all-transactions-${prefix}`,
  async (filter: IRewardFilter, thunkAPI) => {
    try {
      const params = pickBy(filter.fields);
      return await axios.get<ISecondaryReward[]>(`${prefix}/${filter.id}/transactions`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(`update-one-secondary-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.post<IRewardHistory>(`${prefix}/${id}/secondary`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getRewardHistory = createAsyncThunk(
  `get-all-secondary-${prefix}-history`,
  async (fields: IQueryHistoryParam, thunkAPI) => {
    try {
      const params = pickBy(fields);
      const { data: rewardHistory } = await axios.get<IResponse<IRewardHistory[]>>(`${prefix}`, { params });
      return { rewardHistory, id: fields.transactionId };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
