import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Departments, PrivateInternalTasks } from 'shared/enumeration/departments';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const ProductOverview = React.lazy(() => import('./ProductApproval/Overview'));
const ProductApproval = React.lazy(() => import('./ProductApproval/ProductApproval'));
const ProjectOverview = React.lazy(() => import('./ProjectApproval/Overview'));
const ProjectApproval = React.lazy(() => import('./ProjectApproval/ProjectApproval'));
const ExternalStaffApproval = React.lazy(() => import('./ExternalStaffApproval/ExternalStaffApproval'));
const PaymentOverview = React.lazy(() => import('./PaymentApproval/Overview'));
const PaymentApproval = React.lazy(() => import('./PaymentApproval/PaymentApproval'));
const RevenueAllocationOverview = React.lazy(() => import('./RevenueAllocationApproval/Overview'));
const RevenueAllocationApproval = React.lazy(() => import('./RevenueAllocationApproval/RevenueAllocationApproval'));
const TradeOfficeApproval = React.lazy(() => import('./TradeOfficeApproval/TradeOfficeApproval'));

const requestsLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <ProductOverview />
      </ProtectedComponent>
    ),
  },
  {
    path: 'products',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <ProductOverview />
      </ProtectedComponent>
    ),
  },
  {
    path: 'products/:id',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <ProductApproval />
      </ProtectedComponent>
    ),
  },
  {
    path: 'products/:id/tasks/:taskId',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.EXTERNAL_STAFF]}>
        <ProductApproval />
      </ProtectedComponent>
    ),
  },
  {
    path: 'projects',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <ProjectOverview />
      </ProtectedComponent>
    ),
  },
  {
    path: 'projects/:id',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <ProjectApproval />
      </ProtectedComponent>
    ),
  },
  {
    path: 'projects/:id/detail',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN, SystemRoles.ROLE_HEAD]}>
        <ProjectApproval />
      </ProtectedComponent>
    ),
  },
  {
    path: 'external-staff',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <ExternalStaffApproval />
      </ProtectedComponent>
    ),
  },
  {
    path: 'payments',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <PaymentOverview />
      </ProtectedComponent>
    ),
  },
  {
    path: 'payments/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <PaymentApproval />
      </ProtectedComponent>
    ),
  },
  {
    path: 'allocation',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <RevenueAllocationOverview />
      </ProtectedComponent>
    ),
  },
  {
    path: 'allocation/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <RevenueAllocationApproval />
      </ProtectedComponent>
    ),
  },
  {
    path: 'offices',
    element: (
      <ProtectedComponent
        requiredPerms={[
          SystemRoles.ROLE_ADMIN,
          SystemRoles.ROLE_HEAD,
          PrivateInternalTasks.UPDATE_OFFICE_REGISTRATIONS,
        ]}
        department={Departments.DEPT_OFFICE}
      >
        <TradeOfficeApproval />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const RequestsRoutes = () => useRoutes(requestsLayout);
export default RequestsRoutes;
