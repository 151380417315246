import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { AffRequestStatus } from 'shared/enumeration/requestStatus';
import { Status } from 'shared/enumeration/status';
import axios from '../../../../shared/config/axios-interceptor';
import { ITradeOffice } from '../../../../shared/model/tradeOffice.model';
import { IParams } from '../../../../shared/shared-interfaces';

const prefix = 'external/office-registrations';

export interface ITradeOfficeFilter extends IParams {
  provinceCode?: string;
  districtCode?: string;
  requestStatus?: AffRequestStatus;
  status?: Status;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: ITradeOfficeFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ITradeOffice[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITradeOffice>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUpdateTradeRequest {
  id: string;
  requestStatus: AffRequestStatus;
}

export const updateRequestStatus = createAsyncThunk(
  `update-request-status-${prefix}`,
  async (body: IUpdateTradeRequest, thunkAPI) => {
    const { id } = body;
    try {
      const { data } = await axios.put<ITradeOffice>(`${prefix}/${id}/handle-request`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IUpdateEntityStatus {
  id: string;
  status: Status;
}

export const updateEntityStatus = createAsyncThunk(
  `update-status-${prefix}`,
  async (body: IUpdateEntityStatus, thunkAPI) => {
    const { id } = body;
    try {
      const { data } = await axios.put<ITradeOffice>(`${prefix}/${id}/update-status`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
