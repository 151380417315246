import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { externalTaskGroup, SharedInternalTasks } from '../../../../shared/enumeration/departments';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const TaskConfigManagement = React.lazy(() => import('./Overview'));

const TaskConfigUpdate = React.lazy(() => import('./TaskUpdate'));

const TaskConfigLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent
        requiredPerms={[
          ...externalTaskGroup,
          SystemRoles.ROLE_HEAD,
          
        ]}
      >
        <TaskConfigManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: '/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD, SharedInternalTasks.CONFIG_EXTERNAL_TASK]}>
        <TaskConfigUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: '/create',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD, SharedInternalTasks.CONFIG_EXTERNAL_TASK]}>
        <TaskConfigUpdate />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const TaskConfigRoutes = () => useRoutes(TaskConfigLayout);
export default TaskConfigRoutes;
