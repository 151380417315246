import { intersection } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { Departments } from '../../../shared/enumeration/departments';
import { Permission, SystemRoles } from '../../../shared/enumeration/permission';

import { useRouter } from '../../../shared/utils/hooks/useRouter';

interface IProtectedComponentProp {
  children: React.ReactNode;
  requiredPerms: Permission[];
  department?: Departments;
}

export const checkIfUserIsAdmin = (userAuthorities: Permission[]): boolean => {
  return userAuthorities.includes(SystemRoles.ROLE_ADMIN);
};

export const checkOverlapBetweenPerms = (userAuthorities: Permission[], requiredPerms: Permission[]): boolean => {
  const overlappedPerms = intersection(requiredPerms, userAuthorities);
  return Boolean(overlappedPerms.length);
};

export const ProtectedComponent = ({ children, requiredPerms, department }: IProtectedComponentProp) => {
  const { user } = useSelector((state: RootState) => state.authentication);
  const { location } = useRouter();
  
  if (!user) return <Navigate to="/auth/login" state={{ path: location.pathname }} />;

  
  const { authorities } = user;
  if (checkIfUserIsAdmin(authorities)) return <>{children}</>;
  
  const isValidDept = user?.departmentType === department;
  const sufficientPermisions = checkOverlapBetweenPerms(authorities, requiredPerms);

  // No department, just check permisions
  if (!department) return sufficientPermisions ? <>{children}</> : <Navigate to="/403" />;

  // Have department, just check permisions && department
  return (sufficientPermisions && isValidDept) ? <>{children}</> : <Navigate to="/403" />;

};
