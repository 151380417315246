export enum Departments {
  DEPT_VALIDATION = 'DEPT_VALIDATION',
  DEPT_EXPLOITATION = 'DEPT_EXPLOITATION',
  DEPT_DEMARCATION = 'DEPT_DEMARCATION',
  DEPT_OPERATION = 'DEPT_OPERATION', // BỘ PHẬN ĐIỀU HÀNH
  DEPT_TECH = 'DEPT_TECH', // BỘ PHẬN CÔNG NGHỆ
  DEPT_CUSTOMER_SUPPORT = 'DEPT_CUSTOMER_SUPPORT', // BỘ PHẬN CSKH
  DEPT_MARKET_RESEARCH = 'DEPT_MARKET_RESEARCH', // BỘ PHẬN THỊ TRƯỜNG
  DEPT_LEGAL = 'DEPT_LEGAL', // BỘ PHẬN PHÁP LÝ
  DEPT_EXPERT = 'DEPT_EXPERT', // BỘ PHẬN CHUYÊN GIA
  DEPT_OFFICE = 'DEPT_OFFICE', // BỘ PHẬN VĂN PHÒNG ĐẠI DIỆN
  DEPT_INSURANCE = 'DEPT_INSURANCE', // BỘ PHẬN BẢO HIỂM
  DEPT_STAKE = 'DEPT_STAKE', // BỘ PHẬN CỔ TỨC CỔ ĐÔNG
}

export const departmentsArray: Departments[] = [
  Departments.DEPT_VALIDATION,
  Departments.DEPT_EXPLOITATION,
  Departments.DEPT_DEMARCATION,
  Departments.DEPT_OPERATION,
  Departments.DEPT_TECH,
  Departments.DEPT_CUSTOMER_SUPPORT,
  Departments.DEPT_MARKET_RESEARCH,
  Departments.DEPT_LEGAL,
  Departments.DEPT_EXPERT,
  Departments.DEPT_OFFICE,
  Departments.DEPT_INSURANCE,
  Departments.DEPT_STAKE,
];

export const mapDepartments: { [key in Departments]: string } = {
  [Departments.DEPT_VALIDATION]: 'BỘ PHẬN THẨM ĐỊNH',
  [Departments.DEPT_EXPLOITATION]: 'BỘ PHẬN QUẢN LÝ KHAI THÁC',
  [Departments.DEPT_DEMARCATION]: 'BỘ PHẬN ĐỊNH BIÊN',
  [Departments.DEPT_OPERATION]: 'BỘ PHẬN ĐIỀU HÀNH',
  [Departments.DEPT_TECH]: 'BỘ PHẬN CÔNG NGHỆ',
  [Departments.DEPT_CUSTOMER_SUPPORT]: 'BỘ PHẬN CSKH',
  [Departments.DEPT_MARKET_RESEARCH]: 'BỘ PHẬN THỊ TRƯỜNG',
  [Departments.DEPT_LEGAL]: 'BỘ PHẬN PHÁP LÝ',
  [Departments.DEPT_EXPERT]: 'BỘ PHẬN CHUYÊN GIA',
  [Departments.DEPT_OFFICE]: 'BỘ PHẬN VĂN PHÒNG ĐẠI DIỆN',
  [Departments.DEPT_INSURANCE]: 'BỘ PHẬN BẢO HIỂM',
  [Departments.DEPT_STAKE]: 'BỘ PHẬN CỔ TỨC CỔ ĐÔNG',
};

export const departments: Departments[] = [
  Departments.DEPT_VALIDATION,
  Departments.DEPT_EXPLOITATION,
  Departments.DEPT_DEMARCATION,
];

export enum ExternalActivities {
  FEEDBACK_UPDATE = 'FEEDBACK_UPDATE', // review listing
  PRODUCT_INPUT = 'PRODUCT_INPUT', //nhập thông tin bds  + feedback
  QUALITY_ASSESSMENT = 'QUALITY_ASSESSMENT', // đánh giá sản phẩm
  DEMARCATION_INPUT = 'DEMARCATION_INPUT', // định biên
  EXPLOITATION_TYPE_INPUT = 'EXPLOITATION_TYPE_INPUT', //dịch vụ để khai thác trên bđs và thưởng
  DAILY_PAYMENT_INPUT = 'DAILY_PAYMENT_INPUT', // nhập giá sở hữu /ngày bđs
  CORNER_CASE_INPUT = 'CORNER_CASE_INPUT', // Kỳ sở hữu max cua bđs - Thời gian mong muốn thanh khoản
}

/**
 * @description
 * PrivateInternalTasks là các công việc nội bộ đặc thù cho từng bộ phận
 */

export enum PrivateInternalTasks {
  PRODUCT_TYPE_CONFIG = 'PRODUCT_TYPE_CONFIG', // Tạo/cập nhật loại sản phẩm (bao gồm danh mục tài nguyên trên một Loại SP)
  SUCCESS_STAGE_CONFIG = 'SUCCESS_STAGE_CONFIG', // Config mức kỳ thành công theo từng quận huyện

  UPDATE_OFFICE_REGISTRATIONS = 'UPDATE_OFFICE_REGISTRATIONS', // Cập nhật bản ghi văn phòng
  CONFIG_OFFICE_REGISTRATIONS = 'CONFIG_OFFICE_REGISTRATIONS', // Tạo/Cập nhật điều kiện đăng ký của văn phòng đại diện
}

export enum SharedInternalTasks {
  CONFIG_EXTERNAL_TASK = 'CONFIG_EXTERNAL_TASK', // Cấu hình (Thêm, sửa, xóa) công việc cho nhân sự liên kết
  ASSIGN_EXTERNAL_TASK = 'ASSIGN_EXTERNAL_TASK', // Phân công công việc cho nhân sự liên kết
  APPROVE_EXTERNAL_TASK = 'APPROVE_EXTERNAL_TASK', // Phê duyệt công việc cho nhân sự liên kết
}

export const externalTaskGroup = [
  SharedInternalTasks.CONFIG_EXTERNAL_TASK,
  SharedInternalTasks.ASSIGN_EXTERNAL_TASK,
  SharedInternalTasks.APPROVE_EXTERNAL_TASK,
];

export const sharedInternalTasks: SharedInternalTasks[] = [...externalTaskGroup];

export type InternalActivities = SharedInternalTasks | PrivateInternalTasks;

export const activitiesMapping: { [key in ExternalActivities | InternalActivities]: string } = {
  [ExternalActivities.FEEDBACK_UPDATE]: 'Review/Cập nhật thông tin feedback',
  [ExternalActivities.PRODUCT_INPUT]: 'Nhập thông tin BĐS và feedback',
  [ExternalActivities.QUALITY_ASSESSMENT]:
    'Đánh giá Chất lượng sản phẩm (ABCD), đề xuất giá sàn và giá thuê tham chiếu',
  [ExternalActivities.DEMARCATION_INPUT]: 'Định biên giá trị tài sản và nhập giá tốt',
  [ExternalActivities.EXPLOITATION_TYPE_INPUT]: 'Nhập các dịch vụ để khai thác trên BĐS và nhập % thưởng',
  [ExternalActivities.DAILY_PAYMENT_INPUT]: 'Nhập giá sở hữu/ngày',
  [ExternalActivities.CORNER_CASE_INPUT]: ' Thời gian mong muốn thanh khoản (Cho Ủy quyền nhận tiền nhiều lần)',
  [PrivateInternalTasks.PRODUCT_TYPE_CONFIG]: 'Tạo/Cập nhật loại sản phẩm',
  [PrivateInternalTasks.SUCCESS_STAGE_CONFIG]: 'Config mức kỳ thành công theo từng quận huyện',
  [SharedInternalTasks.CONFIG_EXTERNAL_TASK]: 'Cấu hình công việc cho nhân sự liên kết',
  [SharedInternalTasks.ASSIGN_EXTERNAL_TASK]: 'Phân công công việc cho nhân sự liên kết',
  [SharedInternalTasks.APPROVE_EXTERNAL_TASK]: 'Phê duyệt công việc cho nhân sự liên kết',
  [PrivateInternalTasks.UPDATE_OFFICE_REGISTRATIONS]: 'Cập nhật bản ghi văn phòng',
  [PrivateInternalTasks.CONFIG_OFFICE_REGISTRATIONS]: 'Tạo/Cập nhật điều kiện đăng ký văn phòng',
};

type IDeptActivitiesMapping<Acts extends ExternalActivities | InternalActivities> = {
  [key in Departments]: Array<Acts>;
};

export const departmentExternalActivitiesMapping: IDeptActivitiesMapping<ExternalActivities> = {
  [Departments.DEPT_VALIDATION]: [ExternalActivities.FEEDBACK_UPDATE],
  [Departments.DEPT_EXPLOITATION]: [ExternalActivities.PRODUCT_INPUT, ExternalActivities.QUALITY_ASSESSMENT],
  [Departments.DEPT_DEMARCATION]: [
    ExternalActivities.DEMARCATION_INPUT,
    ExternalActivities.EXPLOITATION_TYPE_INPUT,
    ExternalActivities.DAILY_PAYMENT_INPUT,
    ExternalActivities.CORNER_CASE_INPUT,
  ],
  [Departments.DEPT_OPERATION]: [],
  [Departments.DEPT_TECH]: [],
  [Departments.DEPT_CUSTOMER_SUPPORT]: [],
  [Departments.DEPT_MARKET_RESEARCH]: [],
  [Departments.DEPT_LEGAL]: [],
  [Departments.DEPT_EXPERT]: [],
  [Departments.DEPT_OFFICE]: [],
  [Departments.DEPT_INSURANCE]: [],
  [Departments.DEPT_STAKE]: [],
};

export const departmentInternalActivitiesMapping: IDeptActivitiesMapping<InternalActivities> = {
  [Departments.DEPT_VALIDATION]: [
    ...sharedInternalTasks,
    PrivateInternalTasks.PRODUCT_TYPE_CONFIG,
    PrivateInternalTasks.SUCCESS_STAGE_CONFIG,
  ],
  [Departments.DEPT_EXPLOITATION]: [...sharedInternalTasks],
  [Departments.DEPT_DEMARCATION]: [...sharedInternalTasks],
  [Departments.DEPT_OPERATION]: [...sharedInternalTasks],
  [Departments.DEPT_TECH]: [...sharedInternalTasks],
  [Departments.DEPT_CUSTOMER_SUPPORT]: [...sharedInternalTasks],
  [Departments.DEPT_MARKET_RESEARCH]: [...sharedInternalTasks],
  [Departments.DEPT_LEGAL]: [...sharedInternalTasks],
  [Departments.DEPT_EXPERT]: [...sharedInternalTasks],
  [Departments.DEPT_OFFICE]: [
    ...sharedInternalTasks,
    PrivateInternalTasks.UPDATE_OFFICE_REGISTRATIONS,
    PrivateInternalTasks.CONFIG_OFFICE_REGISTRATIONS,
  ],
  [Departments.DEPT_INSURANCE]: [...sharedInternalTasks],
  [Departments.DEPT_STAKE]: [...sharedInternalTasks],
};
