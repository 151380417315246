import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../../../reducers';
import { IInitialState, IResponse } from '../../../../../shared/shared-interfaces';

import {
  getEntities,
  getEntity,
  getEntityConfigs,
  IExternalStaffRegistration,
  updateEntity,
  updateExternalStaffStatus,
  withdrawExternalActivity,
} from './externalStaffRegistration.api';

const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const externalStaffRegistrationAdapter = createEntityAdapter<IExternalStaffRegistration>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'externalStaffRegistrationSlice',
  initialState: externalStaffRegistrationAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IResponse<IExternalStaffRegistration[]>>>
    ) => {
      externalStaffRegistrationAdapter.setAll(state, payload.data.results);
      state.initialState.totalItems = Number(payload.data.count);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IExternalStaffRegistration>) => {
      externalStaffRegistrationAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [getEntityConfigs.fulfilled.type]: (state, { payload }: PayloadAction<IExternalStaffRegistration>) => {
      externalStaffRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntityConfigs.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IExternalStaffRegistration>) => {
      externalStaffRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateExternalStaffStatus.fulfilled.type]: (state, { payload }: PayloadAction<IExternalStaffRegistration>) => {
      externalStaffRegistrationAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateExternalStaffStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [withdrawExternalActivity.fulfilled.type]: (state, { payload }: PayloadAction<IExternalStaffRegistration>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [withdrawExternalActivity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const externalStaffRegistrationSelectors = externalStaffRegistrationAdapter.getSelectors<RootState>(
  (state) => state.externalStaffRegistration
);

const { selectById } = externalStaffRegistrationAdapter.getSelectors();
const getExternalStaffRegistrationState = (rootState: RootState) => rootState.externalStaffRegistration;

export const selectEntityById = (id: string) => {
  return createSelector(getExternalStaffRegistrationState, (state) => selectById(state, id));
};
