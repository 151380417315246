import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { SystemRoles } from '../../../../shared/enumeration/permission';
import { ProtectedComponent } from '../../auth/ProtectedComponent';

const HeadManagement = React.lazy(() => import('./Internal/HeadManagament/HeadManagament'));
const HeadManagementUpdate = React.lazy(() => import('./Internal/HeadManagament/HeadManagementUpdate'));
const StaffManagement = React.lazy(() => import('./Internal/StaffManagement/StaffManagement'));
const StaffManagementUpdate = React.lazy(() => import('./Internal/StaffManagement/StaffManagementUpdate'));
const ExternalStaffManagement = React.lazy(() => import('./ExternalStaffManagement/ExternalStaffManagement'));
const HeadAppointment = React.lazy(() => import('./Internal/HeadManagament/HeadAppointment'));
const HeadAppointmentUpdate = React.lazy(() => import('./Internal/HeadManagament/HeadAppointmentUpdate'));

const UsersManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <StaffManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: 'head-department',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <HeadManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: 'head-department/create',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <HeadManagementUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: 'head-department/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <HeadManagementUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: 'internal-staff',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <StaffManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: 'internal-staff/create',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <StaffManagementUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: 'internal-staff/:id/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <StaffManagementUpdate />
      </ProtectedComponent>
    ),
  },
  {
    path: 'external-staff',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_HEAD]}>
        <ExternalStaffManagement />
      </ProtectedComponent>
    ),
  },
  {
    path: 'head-appointment',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <HeadAppointment />
      </ProtectedComponent>
    ),
  },
  {
    path: 'head-appointment/:departmentType/update',
    element: (
      <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
        <HeadAppointmentUpdate />
      </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const UsersManagementRoutes = () => useRoutes(UsersManagementLayout);
export default UsersManagementRoutes;
