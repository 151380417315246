import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../shared/config/axios-interceptor';
import { IMiniStage } from '../../../../shared/model/miniStage.model';
import { IStageFilter } from '../../../modules/BO/TaskConfig/TaskUpdate';

const prefix = 'external/stages';

export const getEntities = createAsyncThunk(`get-all-${prefix}-periods`, async (fields: IStageFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IMiniStage[]>(`${prefix}/periods`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}-period`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IMiniStage>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentEntity = createAsyncThunk(`get-current-${prefix}-period`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IMiniStage>(`${prefix}/current-period`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
