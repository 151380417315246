import { AxiosResponse } from 'axios';
import axios from '../../../shared/config/axios-interceptor';
import { IMiniStage } from '../../../shared/model/miniStage.model';
export const uploadImage = async (image: File) => {
  try {
    const formData = new FormData();
    formData.append('file', image);
    const { data }: AxiosResponse<{ url: string }> = await axios.post(`files`, formData);
    return data.url;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

interface IImage {
  url: string;
}

export const uploadPrivateImage = async (image: File) => {
  try {
    const formData = new FormData();
    formData.append('file', image);
    const { data }: AxiosResponse<IImage> = await axios.post(`files/private`, formData);
    return data.url;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getMiniStageById = async (id: string) => {
  try {
    const { data } = await axios.get<IMiniStage>(`external/stages/${id}`);
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
