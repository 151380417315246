import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../../../shared/config/axios-interceptor';
import { IReward, IRewardHistory } from '../../../../../../shared/model/reward.model';
import { IParams, IResponse } from '../../../../../../shared/shared-interfaces';

const prefix = 'rewards';

export interface IRewardFilter {
  id: string;
  fields: IParams;
}

interface IClaimReward {
  extensionId: string;
}

export interface IQueryHistoryParam extends IParams {
  extensionId?: string;
  transactionId?: string;
}

export const getListingExtension = createAsyncThunk(`get-all-${prefix}`, async (filter: IRewardFilter, thunkAPI) => {
  try {
    const params = pickBy(filter.fields);
    return await axios.get<IReward[]>(`${prefix}/${filter.id}/extensions`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getRewardHistory = createAsyncThunk(
  `get-all-${prefix}-history`,
  async (fields: IQueryHistoryParam, thunkAPI) => {
    try {
      const params = pickBy(fields);
      const { data: rewardHistory } = await axios.get<IResponse<IRewardHistory[]>>(`${prefix}`, { params });
      return { rewardHistory, id: fields.extensionId };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IReward>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IClaimReward, thunkAPI) => {
  try {
    const { data } = await axios.post<IReward>(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: IClaimReward, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
