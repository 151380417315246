import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import { TaskStatus } from 'shared/enumeration/taskStatus';
import axios from '../../../../shared/config/axios-interceptor';
import { INewTaskAssign, ITaskAssign, ITaskAssignObj } from '../../../../shared/model/taskAssign.model';
import { IParams, IResponse } from '../../../../shared/shared-interfaces';

const prefix = 'tasks';

export interface ITasksFilter extends IParams {
  listingId?: string;
  assigneeId?: string;
  status?: string;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: ITasksFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const taskConfigResponse = await axios.get<IResponse<ITaskAssignObj[]>>(`${prefix}`, { params });
    return taskConfigResponse;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITaskAssignObj>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewTaskAssign, thunkAPI) => {
  try {
    const { data } = await axios.post<ITaskAssign>(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ITaskAssign, thunkAPI) => {
  try {
    const { data } = await axios.put<ITaskAssign>(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const finalizeEntity = createAsyncThunk(`update-one-${prefix}-status`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.put<ITaskAssign>(`${prefix}/${id}/status`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntitiesApproved = createAsyncThunk(
  `external-staff-get-all-${prefix}-approved-with-reward`,
  async (fields: IParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<ITaskAssignObj[]>(`${prefix}/user-assignments`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOverviewEntitiesApproved = createAsyncThunk(
  `get-overview-${prefix}-approved-with-reward`,
  async (id: string, thunkAPI) => {
    try {
      const params = {
        assigneeId: id,
        status: `${TaskStatus.APPROVED},${TaskStatus.REJECTED}`
      };

      return await axios.get<ITaskAssignObj[]>(`${prefix}/overview`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
