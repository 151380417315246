import { ExternalActivities, InternalActivities } from "./departments";

export enum SystemRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_HEAD = 'ROLE_HEAD',
  INTERNAL_STAFF = 'INTERNAL_STAFF',
  EXTERNAL_STAFF = 'EXTERNAL_STAFF',
  ROLE_USER = 'ROLE_USER',
}


export type Permission = SystemRoles | ExternalActivities | InternalActivities;