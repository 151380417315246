/* eslint-disable jsx-a11y/anchor-is-valid */
import { cilHamburgerMenu, cisChevronRightAlt } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCollapse,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CLink,
  CNavItem,
} from '@coreui/react-pro';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes } from 'react-router-dom';
import anftLogo from '../../../assets/img/ANT_logo_main_color.png';
import vreamBlackLogo from '../../../assets/img/vream_black.png';
import vreamLogo from '../../../assets/img/vream_white.png';
import { RootState } from '../../../reducers';
import { frontOffice as routes } from '../../../routes';
import { Roles } from '../../../shared/enumeration/roles';
import { useRouter } from '../../../shared/utils/hooks/useRouter';

export const hideHeadrFootrRoutes = ['/auth', '/register', '/login', '/404', '/500', '/403', '/forgot', '/FO'];

interface ICustomCNavItem {
  href: string;
  children: React.ReactNode;
  customClassName?: string;
  disabled: boolean;
}

enum HeaderCollapse {
  EXCHANGE = 'EXCHANGE',
  ECOSYSTEM = 'ECOSYSTEM',
  BLOCKCHAIN = 'BLOCKCHAIN',
}

const displayCoverRoutes = ['/listings', '/planning'];

const isNavItemActive = (href: string, currPath: string) => href === `#${currPath}` || href === currPath;

// const exchangeUrls = ['/listings', '/listings/primary', '/listings/secondary'];

const TheHeader = () => {
  const { location, redirectView } = useRouter();
  const currentLocation = location.pathname;
  const isBOView = currentLocation.includes('/BO');
  const { user } = useSelector((state: RootState) => state.authentication);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<HeaderCollapse | null>(null);

  const handleCollapse = (item: HeaderCollapse) => () => {
    setCollapse(item === collapse ? null : item);
  };

  const insideNoHeadFootRoutes = hideHeadrFootrRoutes.some((routes) => currentLocation.includes(routes));

  const isListingRoute = (): boolean => {
    const getCurrentRoutes = matchRoutes(routes, currentLocation);
    // Từ currentLocation lấy ra trong routes các đường dẫn đúng nhất trả về dưỚi dạng nhiều
    // sau đó loop qua  getCurrentRoutes và với từng route thì lại loop 1 lần nữa qua array displayCoverRoutes xem có trùng với route đó k
    if (
      getCurrentRoutes &&
      getCurrentRoutes.some((item) => displayCoverRoutes.some((routes) => item.pathname.includes(routes)))
    )
      return true;
    return false;
  };

  const isNotDashboardView = !isBOView && isListingRoute();

  const HeaderBrand = () => (
    <CLink href="http://vream.vn" target="_blank">
      <img
        className="c-sidebar-brand-full px-2 p-lg-0"
        src={isNotDashboardView ? vreamBlackLogo : vreamLogo}
        height={50}
        alt="anftLogo"
      />
    </CLink>
  );

  const LogoBrand = () => (
    <img className="c-sidebar-brand-full" onClick={redirectView('/')} src={anftLogo} height={50} alt="anftLogo" />
  );

  const CustomCNavItem = ({ href, children, customClassName, disabled }: ICustomCNavItem) => {
    return (
      <CNavItem
        href={href}
        disabled={disabled}
        className={`${customClassName || ''} header-text d-none d-lg-block cursor-pointer  ${
          isNotDashboardView ? 'header-listing-text' : ''
        } ${isNavItemActive(href, location.pathname) ? ' text-white' : 'color-muted'}`}
      >
        {children}
      </CNavItem>
    );
  };

  const CustomCCollapseItem = ({ href, children, customClassName, disabled }: ICustomCNavItem) => {
    const activeClassname = isNavItemActive(href, location.pathname) ? 'nav-item-header-active' : '';
    return (
      <CNavItem
        href={href}
        disabled={disabled}
        className={`${customClassName || ''} text-white text-sm fw-500 ${activeClassname} ${
          isNotDashboardView ? 'header-listing-text' : ''
        }`}
        onClick={() => {
          setDropdownVisible(false);
          setCollapse(null)
        } }
      >
        <CIcon
          icon={cisChevronRightAlt}
          size="sm"
          className={`pe-1 me-2 ${isNavItemActive(href, location.pathname) ? 'd-none' : ''}`}
        />
        {children}
      </CNavItem>
    );
  };

  return (
    <CHeader
      position="fixed"
      className={
        isBOView || insideNoHeadFootRoutes
          ? 'd-none'
          : `custom-header-nav background-color-header position-relative p-0`
      }
    >
      <CContainer className="justify-content-between my-3 px-0">
        <CHeaderNav>
          <CHeaderBrand component={HeaderBrand}></CHeaderBrand>
        </CHeaderNav>
        <CHeaderNav className="order-1 order-lg-0">
          <CustomCNavItem href="#" customClassName="" disabled={false}>
            Trang chủ
          </CustomCNavItem>
          <CustomCNavItem href="#/planning" customClassName="" disabled={false}>
            Quy hoạch
          </CustomCNavItem>
          <CDropdown variant="nav-item" popper={false} className="header-text">
            <CDropdownToggle
              className={`  d-none d-lg-block color-muted ${isNotDashboardView ? 'header-listing-text' : ''}`}
            >
              Sàn khai thác
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem href={`#/listings/primary`}>Sàn Sơ Cấp</CDropdownItem>
              <CDropdownItem href={`#/listings/secondary`}>Sàn Thứ Cấp</CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem href="#">Dịch vụ hỗ trợ</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          <CustomCNavItem href="#" customClassName="" disabled>
            Thống kê thị trường
          </CustomCNavItem>
          <CustomCNavItem href="#" customClassName="" disabled>
            FAQ
          </CustomCNavItem>
          <CustomCNavItem href="#" customClassName="" disabled>
            Đào tạo
          </CustomCNavItem>
          <CDropdown variant="nav-item" popper={false} className="header-text">
            <CDropdownToggle
              className={`color-muted d-none d-lg-block ${isNotDashboardView ? 'header-listing-text' : ''}`}
            >
              Hệ sinh thái
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem target="_blank" href="https://sale.anfteco.io/">
                Platform Phân bổ token
              </CDropdownItem>
              <CDropdownItem target="_blank" href="https://app.anfteco.io/">
                D'App
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          <CDropdown variant="nav-item" popper={false} className="header-text">
            <CDropdownToggle
              className={`color-muted d-none d-lg-block ${isNotDashboardView ? 'header-listing-text' : ''}`}
            >
              Ví Blockchain
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem target="_blank" href="https://metamask.io/">
                Metamask
              </CDropdownItem>
              <CDropdownItem target="_blank" href="https://safepal.io/">
                Safepal
              </CDropdownItem>
              <CDropdownItem target="_blank" href="https://trustwallet.com/">
                Trustwallet
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          {/* <CustomCNavItem href="#/auth/login/FO" customClassName="" disabled={false}> */}
          {user ? (
            <CustomCNavItem href={Roles.USER ? '#/FO' : '#/BO'} customClassName="" disabled={false}>
              Trang cá nhân
            </CustomCNavItem>
          ) : (
            <CustomCNavItem href={'#/auth/login/FO'} customClassName="" disabled={false}>
              Đăng nhập
            </CustomCNavItem>
          )}

          <CDropdown
            variant="nav-item"
            className="d-block d-lg-none btn-secondary my-1"
            style={{ position: 'initial' }}
            autoClose="outside"
            visible={dropdownVisible}
          >
            <CDropdownToggle caret={false}>
                <CIcon
                icon={cilHamburgerMenu}
                size="xxl"
                className={`mt-1 ${isNotDashboardView ? 'header-listing-text' : 'text-white'}`}
                onClick={() => setDropdownVisible(true)}
              />
            </CDropdownToggle>
            <CDropdownMenu className={`${isNotDashboardView ? 'mobile-dropdown' : 'db-mobile-dropdown'}`}>
              <ul className="list-style-none">
                <CustomCCollapseItem disabled={false} href="/">
                  Trang chủ
                </CustomCCollapseItem>
                <CustomCCollapseItem disabled={false} href="#/planning">
                  Quy hoạch
                </CustomCCollapseItem>
                <li
                  onClick={handleCollapse(HeaderCollapse.EXCHANGE)}
                  className={`nav-item cursor-pointer text-sm fw-500 `}
                >
                  <a className={`nav-link ${isNotDashboardView ? 'header-listing-text' : 'text-white'}`}>
                    <CIcon icon={cisChevronRightAlt} size="sm" className={`pe-1 me-2 `} />
                    Sàn khai thác BĐS
                  </a>
                </li>
                <CCollapse visible={collapse === HeaderCollapse.EXCHANGE} className="d-lg-none w-100">
                  <ul className="list-style-none">
                    <CustomCCollapseItem disabled={false} href={`#/listings/primary`}>
                      Sàn sơ cấp
                    </CustomCCollapseItem>
                    <CustomCCollapseItem disabled={false} href={`#/listings/secondary`}>
                      Sàn thứ cấp
                    </CustomCCollapseItem>
                    <CustomCCollapseItem disabled={false} href="#">
                      Dịch vụ hỗ trợ
                    </CustomCCollapseItem>
                  </ul>
                </CCollapse>
                <CustomCCollapseItem disabled href="#">
                  Thống kê thị trường
                </CustomCCollapseItem>
                <CustomCCollapseItem disabled href="#">
                  FAQ
                </CustomCCollapseItem>
                <CustomCCollapseItem disabled href="#">
                  Đào tạo
                </CustomCCollapseItem>
                <li
                  onClick={handleCollapse(HeaderCollapse.ECOSYSTEM)}
                  className={`nav-item cursor-pointer text-sm fw-500 `}
                >
                  <a className={`nav-link ${isNotDashboardView ? 'header-listing-text' : 'text-white'}`}>
                    <CIcon icon={cisChevronRightAlt} size="sm" className={`pe-1 me-2 `} />
                    Hệ sinh thái
                  </a>
                </li>
                <CCollapse visible={collapse === HeaderCollapse.ECOSYSTEM} className="d-lg-none w-100">
                  <ul className="list-style-none">
                    <CustomCCollapseItem disabled={false} href="https://sale.anfteco.io/">
                      Platform Phân bổ token
                    </CustomCCollapseItem>
                    <CustomCCollapseItem disabled={false} href="https://app.anfteco.io/">
                      D'App
                    </CustomCCollapseItem>
                  </ul>
                </CCollapse>
                <li
                  onClick={handleCollapse(HeaderCollapse.BLOCKCHAIN)}
                  className={`nav-item cursor-pointer text-sm fw-500 `}
                >
                  <a className={`nav-link ${isNotDashboardView ? 'header-listing-text' : 'text-white'}`}>
                    <CIcon icon={cisChevronRightAlt} size="sm" className={`pe-1 me-2 `} />
                    Ví Blockchain
                  </a>
                </li>
                <CCollapse visible={collapse === HeaderCollapse.BLOCKCHAIN} className="d-lg-none w-100">
                  <ul className="list-style-none">
                    <CustomCCollapseItem disabled={false} href="https://metamask.io/">
                      Metamask
                    </CustomCCollapseItem>
                    <CustomCCollapseItem disabled={false} href="https://safepal.io/">
                      Safepal
                    </CustomCCollapseItem>
                    <CustomCCollapseItem disabled={false} href="https://trustwallet.com/">
                      Trustwallet
                    </CustomCCollapseItem>
                  </ul>
                </CCollapse>
                {user ? (
                  <CButton
                    onClick={redirectView(user.role === Roles.USER ? '/FO' : '/BO')}
                    className=" border-0 text-anft-gradiant m-auto mx-2 my-2"
                  >
                    Trang cá nhân
                  </CButton>
                ) : (
                  <CButton
                    onClick={redirectView('/auth/login/FO')}
                    className=" border-0 text-anft-gradiant m-auto mx-2 my-2"
                  >
                    Đăng Nhập
                  </CButton>
                )}
              </ul>
            </CDropdownMenu>
          </CDropdown>
        </CHeaderNav>
        <CHeaderNav className="ms-auto ms-lg-0 me-2">
          <CHeaderBrand component={LogoBrand}></CHeaderBrand>
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default TheHeader;
