import { ethers } from "ethers";
import { BLOCKCHAIN_NETWORK, USING_TESTNET, _window } from "../../../shared/config/constants";
import testTokenImplementation from './deployments/goerli/Token_Implementation.json';
import testTokenProxy from './deployments/goerli/Token_Proxy.json';
import mainTokenImplementation from './deployments/bsc-mainnet/Token_Implementation.json';
import mainTokenProxy from './deployments/bsc-mainnet/Token_Proxy.json';
import testnetBroadcaster from './deployments/goerli/Validation.json';
import mainnetBrodcaster from './deployments/bsc-mainnet/Validation.json';
import { Token } from "./typechain/Token";
import { Validation } from "./typechain";


interface IContractGenerator {
  signer?: ethers.providers.JsonRpcSigner;
  provider?: ethers.providers.Web3Provider;
}

export const TOKEN_INSTANCE = ({ signer, provider }: IContractGenerator): Token | null => {
  try {
    if (!signer && !provider) throw String('requires either signer or provider to generate a instance');
    const contractAddress = USING_TESTNET ? testTokenProxy.address : mainTokenProxy.address;
    const contractABI = USING_TESTNET ?  testTokenImplementation.abi : mainTokenImplementation.abi;
    return new ethers.Contract(contractAddress, contractABI, signer || provider) as Token;
  } catch (error) {
    console.log(`Error getting token instance: ${error}`);
    return null;
  }
};

export const BROADCAST_INSTANCE = ({ signer, provider }: IContractGenerator): Validation | null => {
  try {
    if (!signer && !provider) throw String('requires either signer or provider to generate a instance');
    const contractAddress = USING_TESTNET ? testnetBroadcaster.address : mainnetBrodcaster.address;
    const contractABI = USING_TESTNET ?  testnetBroadcaster.abi : mainnetBrodcaster.abi;
    return new ethers.Contract(contractAddress, contractABI, signer || provider) as Validation;
  } catch (error) {
    console.log(`Error getting broadcaster instance: ${error}`);
    return null;
  }
};

export const promptUserToSwitchChain = () => {
  _window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{chainId: BLOCKCHAIN_NETWORK.chainId}],
  });
};