import { IWindowEth } from '../shared-interfaces';
import { EnvEnum, _env } from '../utils/envDetect';
import { BSC_MAINNET_CONFIG, BSC_TESTNET_CONFIG } from './chain';

export const SERVER_API_URL =
  _env === EnvEnum.PROD
    ? 'https://qlts-anft.icetea.click/api/'
    : _env === EnvEnum.DEV
    ? 'https://qlts-anft.icetea.click/api/'
    : 'http://localhost:4000/api/';

export const DAPP_SITE_URL = _env === EnvEnum.PROD ? 'https://dapp--anft.icetea.click/' : 'https://dapp--anft.icetea.click/';

export const GOOGLE_MAP_KEY = 'AIzaSyD6fpm34e4hPyLRQsKP8cAQQfw2ZGE5g8s';

export const APP_DATE_FORMAT = 'HH:mm - DD/MM/YY';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const HTML_DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const HTML_DATE_TIME_INPUT_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_SUBMIT_FORMAT = 'YYYY-MM-DD';

export const _window = window as unknown as IWindowEth;

export const SAFE_DATE_MIN = new Date(-8640000000000000);
export const SAFE_DATE_MAX = new Date(8640000000000000);

export const USING_TESTNET = _env !== EnvEnum.PROD; // Network toggler
export const BLOCKCHAIN_NETWORK = USING_TESTNET ? BSC_MAINNET_CONFIG : BSC_MAINNET_CONFIG;
